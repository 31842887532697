import React from "react";

function SubmitFormModal({ gotoParent }) {
  // const [showOverlay, setShowOverlay] = useState(false);

  const handleClose = () => {
    // gotoParent();
  };
  return (
    <div className="overlaySubmit">
      <div className="text-end">
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          className="close"
          onClick={() => gotoParent("close")}
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.35718 3.46109L28.1795 27.2832M27.3027 3.46082L3.48028 27.2833"
              stroke="#636261"
              strokeWidth="6"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
      <div className="overlaybtn text-center ">
        <p>Are you sure you want to submit the form ?</p>
        <button className="startSession" onClick={() => gotoParent("submit")}>
          submit
        </button>
      </div>
    </div>
  );
}

export default SubmitFormModal;
