// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About{
    padding: 8rem 0rem;
}
.aboutWrapper{
    width: 80%;
    margin: 0 auto;
}
.About h1{
     color: #404040;
font-family: 'Poppins';
font-size: 2.5rem;
font-weight: 600;
}

.About p{
    font-family: 'Roboto';
    color: #646464;
    font-weight: 400;
    margin-top: 2rem;
}
.aboutWrapper .row h3{
    font-family: 'Roboto';
display: inline;

font-size: 1.3rem;
font-weight: 600;
text-transform: uppercase;


color: #404040;
border-bottom: 1px solid #646464;

}
.aboutWrapper .row p{
font-family: 'Poppins';
text-transform: capitalize;
color: #646464;
line-height: 1.8rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/aboutpage/about.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,cAAc;AAClB;AACA;KACK,cAAc;AACnB,sBAAsB;AACtB,iBAAiB;AACjB,gBAAgB;AAChB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,qBAAqB;AACzB,eAAe;;AAEf,iBAAiB;AACjB,gBAAgB;AAChB,yBAAyB;;;AAGzB,cAAc;AACd,gCAAgC;;AAEhC;AACA;AACA,sBAAsB;AACtB,0BAA0B;AAC1B,cAAc;AACd,mBAAmB;AACnB","sourcesContent":[".About{\n    padding: 8rem 0rem;\n}\n.aboutWrapper{\n    width: 80%;\n    margin: 0 auto;\n}\n.About h1{\n     color: #404040;\nfont-family: 'Poppins';\nfont-size: 2.5rem;\nfont-weight: 600;\n}\n\n.About p{\n    font-family: 'Roboto';\n    color: #646464;\n    font-weight: 400;\n    margin-top: 2rem;\n}\n.aboutWrapper .row h3{\n    font-family: 'Roboto';\ndisplay: inline;\n\nfont-size: 1.3rem;\nfont-weight: 600;\ntext-transform: uppercase;\n\n\ncolor: #404040;\nborder-bottom: 1px solid #646464;\n\n}\n.aboutWrapper .row p{\nfont-family: 'Poppins';\ntext-transform: capitalize;\ncolor: #646464;\nline-height: 1.8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
