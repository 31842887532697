// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionSelectDays label {
    text-transform: capitalize;
  }
  .QuestionSelectDays p {
    color: #000;
    font-weight: 400;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  .active {
    border-bottom: 1px solid black !important;
  }
  ul li{color: rgba(0, 0, 0, 0.4);
  cursor: pointer;}`, "",{"version":3,"sources":["webpack://./src/components/patientform/questionSelectDays/questionSelectDays.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,gBAAgB;IAChB,0BAA0B;IAC1B,kCAAkC;IAClC,mBAAmB;IACnB,iBAAiB;EACnB;EACA;IACE,yCAAyC;EAC3C;EACA,MAAM,yBAAyB;EAC/B,eAAe,CAAC","sourcesContent":[".QuestionSelectDays label {\n    text-transform: capitalize;\n  }\n  .QuestionSelectDays p {\n    color: #000;\n    font-weight: 400;\n    text-transform: capitalize;\n    font-family: \"Poppins\", sans-serif;\n    margin-bottom: 1rem;\n    font-size: 0.9rem;\n  }\n  .active {\n    border-bottom: 1px solid black !important;\n  }\n  ul li{color: rgba(0, 0, 0, 0.4);\n  cursor: pointer;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
