import { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const isLoggedIn = () => {
    const token = localStorage.getItem("token1");
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  const LogIn = (response) => {
    localStorage.setItem("token1", response.data.token);
    navigate("/Home");
  };

  const LogOut = () => {
    localStorage.removeItem("token1");
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, LogIn, LogOut }}>
      {children}
    </AuthContext.Provider>
  );
};
