// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formWrapper {
  width: 85%;
  margin: 0 auto;
}
.form {
  padding: 5rem 0;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.form .logo img {
  width: 3.5rem;
}
.logintextlogo img {
  width: 11.5rem;
}
.form h3 {
  text-align: center;
  text-transform: capitalize;
  font-size: 1.4rem;
  margin-top: 1rem;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/Form.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,eAAe;EACf,kCAAkC;EAClC,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".formWrapper {\n  width: 85%;\n  margin: 0 auto;\n}\n.form {\n  padding: 5rem 0;\n  font-family: \"Poppins\", sans-serif;\n  position: relative;\n}\n.form .logo img {\n  width: 3.5rem;\n}\n.logintextlogo img {\n  width: 11.5rem;\n}\n.form h3 {\n  text-align: center;\n  text-transform: capitalize;\n  font-size: 1.4rem;\n  margin-top: 1rem;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
