import { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthContextProvider } from "./context/AuthContext";

import ProtectedRoutes from "./ProtectedRoutes";

import Form from "./components/Auth/Form";
import LoginForm from "./components/Auth/LoginForm/LoginForm";
import NewPassword from "./components/Auth/Newpassword/NewPassword";
import ResetPass from "./components/Auth/ResetPass/ResetPass";
import Main from "./layout/Main";
import Home from "./components/Home/Home";
import About from "./pages/aboutpage/About";
import { Questions } from "./components/patientform/PatientForm/Questions";
import ContactUs from "./pages/contact us/ContactUs";
import Header from "./components/Header/Header";
import StartSessionBtn from "./components//Header/startSessionbtn/StartSessionbtn";
import EndSessionBtn from "./components//Header/EndSessionBtn/EndSessionBtn";
import FormFooterbtns from "./components/FormFooterButtons/FormFooterbtns";

function routes() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <Form
                flag={false}
                text="hello again!"
                childComp={<LoginForm />}
              />
            }
          />
          <Route
            path="/resetpassword"
            element={
              <Form
                flag={true}
                buttontext="back"
                text="forgot password"
                childComp={<ProtectedRoutes component={<ResetPass />} />}
              />
            }
          />
          <Route
            path="/newpassword"
            element={
              <Form
                flag={true}
                buttontext="back to login"
                text="Reset password"
                childComp={<NewPassword />}
              />
            }
          />
          <Route
            path="/Home"
            element={
              <Main
                flag={true}
                childComp1={<ProtectedRoutes component={<Home />} />}
                childComp2={
                  <ProtectedRoutes
                    component={<Header childComp={<StartSessionBtn />} />}
                  />
                }
              />
            }
          />
          <Route
            path="/viewpatientForm"
            element={
              <Main
                flag={false}
                showEndSesion={false}
                childComp1={
                  <ProtectedRoutes component={<Questions view={true} />} />
                }
                childComp2={<ProtectedRoutes component={<Header />} />}
              />
            }
          />
          <Route
            path="/newpatientForm"
            element={
              <Main
                flag={false}
                childComp1={
                  <Questions
                    view={false}
                    completed={false}
                    childComp={
                      <ProtectedRoutes
                        component={<FormFooterbtns completed={false} />}
                      />
                    }
                  />
                }
                childComp2={
                  <ProtectedRoutes
                    component={<Header childComp={<EndSessionBtn />} />}
                  />
                }
              />
            }
          />
          <Route
            path="/completedpatientForm"
            element={
              <Main
                flag={false}
                childComp1={
                  <Questions
                    view={false}
                    completed={true}
                    childComp={
                      <ProtectedRoutes
                        component={<FormFooterbtns completed={true} />}
                      />
                    }
                  />
                }
              />
            }
          />
          <Route
            path="/aboutus"
            element={
              <Main
                childComp2={
                  <ProtectedRoutes component={<Header childComp={""} />} />
                }
                childComp1={<ProtectedRoutes component={<About />} />}
              />
            }
          />
          <Route
            path="/contactus"
            element={
              <Main
                childComp2={
                  <ProtectedRoutes component={<Header childComp={""} />} />
                }
                childComp1={<ProtectedRoutes component={<ContactUs />} />}
              />
            }
          />
          {/* <Route path="/" element={<App />} /> */}
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default routes;
