import React,{useContext} from "react";
import { Mycontext } from "../../layout/Main";
import { Slider } from "@mui/material";
function AgeSlider() {
  function valuetext(value) {
    return `${value}°C`;
  }

  const minDistance = 10;

  const {value1, setValue1}=useContext(Mycontext)

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center position-relative">
        <p className="age mb-0">age</p>
        <div className="ageRnages d-flex" style={{}}>
          <p
            className="mb-0 mx-1"
            style={{ color: "#ED1C24", fontWeight: "bold" }}
          >
            {value1[0]}
          </p>
          <span
            style={{
              color: "#ED1C24",
              fontWeight: "bold",
          
              left: "14px",
              bottom: ".2px",
            }}
          >
            -
          </span>
          <p
            style={{ color: "#ED1C24", fontWeight: "bold" }}
            className="mb-0 mx-1"
          >
            {value1[1]}
          </p>
        </div>
      </div>
      <Slider
        getAriaLabel={() => "Minimum distance"}
        value={value1}
        onChange={handleChange1}
        getAriaValueText={valuetext}
        disableSwap
      />
    </div>
  );
}

export default AgeSlider;
