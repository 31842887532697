// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textbox input {
  width: 218.75px;
  height: 40.1px;

  background: #fff2f3;
  border: 1px solid #8e8e8e;
  border-radius: 14.5833px;
  outline: none;

  font-family: "Poppins";
color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 1px;
  padding-left: 2rem;

}
.textbox {
  /* position: relative; */
}
.textbox .calIcon {
  position: absolute;
  color: rgba(0, 0, 0, 0.2);
  top: 0.7rem;
  left: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/patientform/questionTextBox/textBox/textbox.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;;EAEd,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;EACxB,aAAa;;EAEb,sBAAsB;AACxB,YAAY;EACV,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,4BAA4B;;EAE5B,mBAAmB;EACnB,kBAAkB;;AAEpB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,UAAU;AACZ","sourcesContent":[".textbox input {\n  width: 218.75px;\n  height: 40.1px;\n\n  background: #fff2f3;\n  border: 1px solid #8e8e8e;\n  border-radius: 14.5833px;\n  outline: none;\n\n  font-family: \"Poppins\";\ncolor: black;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 18px;\n  /* identical to box height */\n\n  letter-spacing: 1px;\n  padding-left: 2rem;\n\n}\n.textbox {\n  /* position: relative; */\n}\n.textbox .calIcon {\n  position: absolute;\n  color: rgba(0, 0, 0, 0.2);\n  top: 0.7rem;\n  left: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
