import React from "react";
import "./contactus.css";
import { TextField } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
function ContactUs() {
  return (
    <div className="ContactUs">
      <div className="contactUsWrapper">
        <h3>CONTACT US</h3>
        <h1>Get In Touch</h1>

        <div className="row mt-5">
          <div className="col-lg-12 d-flex flex-col justify-content-center">
            <div className="info">
              <p>Contact us for a qoute, help or to join the team.</p>
              <div className="d-flex align-items-center justify-content-center gap-4 mb-5 mt-5">
                <span className="Icon">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 35 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.4857 0.970703C7.82879 0.970703 0 8.70391 0 18.2432C0 21.6815 1.58331 27.009 3.2381 29.7582L17.4857 53.4278L31.7333 29.7582C33.3885 27.009 34.9714 21.6813 34.9714 18.2432C34.9714 8.70402 27.1428 0.970703 17.4857 0.970703ZM17.4857 9.92657C22.1355 9.92657 25.9049 13.65 25.9049 18.2431C25.9049 22.8361 22.1355 26.5596 17.4857 26.5596C12.8359 26.5596 9.06655 22.8361 9.06655 18.2431C9.06655 13.65 12.8359 9.92657 17.4857 9.92657Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <p className="mb-0">2972 Westheimer, Illinois </p>
              </div>

              {/* <div className="d-flex align-items-center justify-content-center gap-4 mb-5 ">
                <span className="Icon">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 44 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M42.2555 36.4571L41.9954 35.9369C41.2673 34.2208 32.3226 31.6206 31.5945 31.5687L31.0224 31.6205C29.9302 31.8284 28.7341 32.8166 26.394 34.8447C25.9261 35.2608 25.3018 35.3649 24.7297 35.0526C21.6615 33.3364 17.9172 29.9041 16.045 27.824C14.0168 25.588 11.5726 21.8955 10.4285 18.931C10.2206 18.359 10.4285 17.735 10.8446 17.319C13.4967 14.9266 14.6408 13.7829 14.7449 12.5346C14.7968 11.8065 13.237 2.60174 11.6245 1.71767L11.1565 1.40569C10.1165 0.729526 8.55629 -0.258632 6.84015 0.105584C6.42406 0.209695 6.00799 0.365681 5.64414 0.573541C4.50041 1.30232 1.64008 3.27861 0.339966 5.82654C-0.439952 7.43859 -0.804131 22.0519 10.1168 34.377C20.9337 46.598 34.2988 47.118 36.275 46.702H36.3269L36.4829 46.6501C39.1872 45.662 41.4755 43.114 42.3595 42.0217C43.9715 40.0977 42.8797 37.7572 42.2553 36.4571L42.2555 36.4571Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <p className="mb-0">(406) 555-0120 </p>
              </div> */}
              <div className="d-flex align-items-center justify-content-center gap-4 mb-5">
                <span className="Icon">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 49 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.71845 0.394531C3.22474 0.394531 2.75393 0.49267 2.32371 0.665599L23.0677 19.1429C23.7905 19.7875 24.5929 19.7871 25.3145 19.1429L46.0186 0.665599C45.5885 0.492668 45.1177 0.394531 44.6239 0.394531H3.71812H3.71845ZM0.0384771 3.5903C0.0151312 3.75976 0 3.93702 0 4.11342V28.9048C0 29.4132 0.107217 29.8974 0.290524 30.3379L14.6031 16.5865L0.0384771 3.5903ZM48.3042 3.5903L33.778 16.567L48.0718 30.3185C48.2491 29.8836 48.3429 29.4046 48.3429 28.9049V4.11346C48.3429 3.93751 48.3273 3.75982 48.3044 3.59034L48.3042 3.5903ZM30.989 19.065L27.7932 21.9313C25.7618 23.7454 22.6196 23.7423 20.588 21.9313L17.3922 19.0839L3.29213 32.603C3.43004 32.6185 3.57616 32.622 3.71841 32.622H44.6242C44.7595 32.622 44.8996 32.6159 45.031 32.603L30.9885 19.0651L30.989 19.065Z"
                      fill="black"
                    />
                  </svg>
                </span>

                <p className="mb-0">zodom@emtranpro.com</p>
              </div>
              <div className="d-flex align-items-center justify-content-center gap-4 mb-5">
                <span className="Icon" style={{ marginLeft: 15 }}>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 49 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.71845 0.394531C3.22474 0.394531 2.75393 0.49267 2.32371 0.665599L23.0677 19.1429C23.7905 19.7875 24.5929 19.7871 25.3145 19.1429L46.0186 0.665599C45.5885 0.492668 45.1177 0.394531 44.6239 0.394531H3.71812H3.71845ZM0.0384771 3.5903C0.0151312 3.75976 0 3.93702 0 4.11342V28.9048C0 29.4132 0.107217 29.8974 0.290524 30.3379L14.6031 16.5865L0.0384771 3.5903ZM48.3042 3.5903L33.778 16.567L48.0718 30.3185C48.2491 29.8836 48.3429 29.4046 48.3429 28.9049V4.11346C48.3429 3.93751 48.3273 3.75982 48.3044 3.59034L48.3042 3.5903ZM30.989 19.065L27.7932 21.9313C25.7618 23.7454 22.6196 23.7423 20.588 21.9313L17.3922 19.0839L3.29213 32.603C3.43004 32.6185 3.57616 32.622 3.71841 32.622H44.6242C44.7595 32.622 44.8996 32.6159 45.031 32.603L30.9885 19.0651L30.989 19.065Z"
                      fill="black"
                    />
                  </svg>
                </span>

                <p className="mb-0">bspencer@emtranpro.com</p>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6">
            <div className="contactusForm">
              <div className="name mb-4">
                {" "}
                <TextField
                  sx={{ width: "100%" }}
                  id="standard-basic"
                  label="Enter your name"
                  variant="standard"
                />
              </div>
              <div className="email mb-4">
                {" "}
                <TextField
                  sx={{ width: "100%" }}
                  id="standard-basic"
                  label="Enter your mail"
                  variant="standard"
                />
              </div>
              <div className="message mb-4">
                <TextareaAutosize
                  aria-label="empty textarea"
                  minRows={4}
                  placeholder="Enter your message"
                  style={{ width: "100%", padding: "1rem 1rem" }}
                />
              </div>
              <div className="text-end">
                <button>SUBMIT</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
