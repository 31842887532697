import React from "react";
import "./FilterPatients.css";
import RadioGroup from "@mui/material/RadioGroup";
// import RadioBtn from "../../PatientForm/Radiobtn/RadioBtn";
import AgeSlider from "../../Slider/AgeSlider";
import DatePickerComp from "../../Datepicker/DatePickerComp";

function Filter({ hideFilter }) {
  const labels = ["male", "female", "others"];

  const gotoParent = () => {
    hideFilter();
  };
  return (
    <div className="filterDropdown">
      <div
        className="marginBottom my-2"
        style={{ borderBottom: "0.2px solid rgba(0,0,0,0.2)" }}
      ></div>
      <div className="addpatientform px-4 py-4 text-capitalize">
        <div className="row  ">
          <div className="date col-5 ">
            <p className="label">date</p>
            {/* <DatePickerComp /> */}
            <input
              type="text"
              style={{
                height: "2.4rem",
                border: "none",
                background: "#EEEFFF",
                boxShadow: "0px 2px 48px rgba(0, 0, 0, 0.06)",
                borderRadius: "5px",
                width: "13rem",
              }}
            />
          </div>
          <div className="col-7">
            <div className="gender">
              <p className="label">gender</p>
              <input
                type="text"
                style={{
                  height: "2.4rem",
                  border: "none",
                  background: "#EEEFFF",
                  boxShadow: "0px 2px 48px rgba(0, 0, 0, 0.06)",
                  borderRadius: "5px",
                  width: "13rem",
                }}
              />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {/* {labels.map((option, index) => {
                  return (
                    <RadioBtn choice={option} index={index} value={labels} optionsArray/>
                  );
                })} */}
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <AgeSlider />
          </div>
        </div>
      </div>
      <div className="Buttons text-center">
        <button className="reset btn text-capitalize ">reset</button>
        <button
          className="accept btn text-capitalize mx-2"
          onClick={gotoParent}
        >
          accept
        </button>
      </div>
    </div>
  );
}

export default Filter;
