import React, { useEffect, useState, useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import "./CompletedSessions.css";
import { useNavigate, Link } from "react-router-dom";
import { date } from "yup/lib/locale";
import { Mycontext } from "../../../layout/Main";
import { AuthContext } from "../../../context/AuthContext";
function CompletedSessions({ searchText }) {
  const responses = JSON.parse(localStorage.getItem("responses"));
  const rows = [responses];
  const { value1, setValue1 } = useContext(Mycontext);
  const { LogOut } = useContext(AuthContext);
  const token = localStorage.getItem("token1");
  const patientLang = localStorage.getItem("patientLang");
  const [patientsdata, setPatientsData] = useState([]);
  const [allPatientsData, setAllPatientsData] = useState([]);

  const navigate = useNavigate();
  function createData(date, id, patient, age, gender, language) {
    return { date, id, patient, age, gender, language };
  }
  // console.log("rows", rows[0]);
  // console.log(token);

  // const handleClick=()=>{
  //   navigate("/patientForm",{state:{flag:false,sessionEnd:true}})
  // }

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_HOST_URL}api/session/history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setPatientsData(response.data.data);
        setAllPatientsData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          LogOut();
        }
      });
  }, []);

  useEffect(() => {
    const searchedPatient = allPatientsData.filter((item) => {
      return item.name.toUpperCase().includes(searchText.toUpperCase());
    });
    setPatientsData([...searchedPatient]);
  }, [searchText]);

  useEffect(() => {
    console.log("in-useEffect");
    console.log(value1);
    let filtereddata = patientsdata.filter(
      (data) => data.age > value1[0] && data.age < value1[1]
    );
    setPatientsData(filtereddata);
    console.log(filtereddata);
  }, [value1]);

  const deletedata = (e) => {
    console.log(e.target.id);
    let filtereddata = patientsdata.filter(
      (data) => data.id !== parseInt(e.target.id)
    );
    setPatientsData(filtereddata);
  };

  const handleViewMoreDetails = (id) => {
    navigate("/viewpatientForm", { state: { id: id, view: true } });
  };

  return (
    <TableContainer component={Paper} className="mt-5">
      <Table sx={{ minWidth: 670 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">date</TableCell>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">patient</TableCell>
            <TableCell align="center">age</TableCell>
            <TableCell align="center">gender</TableCell>
            <TableCell align="center">language</TableCell>
            <TableCell align="center">Details</TableCell>
            {/* <TableCell align="center"></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {patientsdata.map((data, index) => {
            const date = new Date(data?.createdAt);
            return (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "150px" }}
                >
                  {date.toLocaleDateString("es-CL")}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "100px" }}
                >
                  {data.id}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "150px" }}
                >
                  {data.name}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "80px" }}
                >
                  {data.age}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "150px" }}
                >
                  {data.gender}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "150px" }}
                >
                  {data.language}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "150px" }}
                >
                  <div
                    // to="/viewpatientForm"
                    style={{
                      textTransform: "capitalize",
                      color: "#ED1C24",
                      cursor: "pointer",
                      textDecoration: "underLine",
                    }}
                    href="#"
                    onClick={() => handleViewMoreDetails(data?.id)}
                  >
                    view details
                  </div>
                </TableCell>
                {/* <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ minWidth: "150px" }}
                >
                  <button
                    className="buttonDelete"
                    id={data.id}
                    onClick={deletedata}
                    style={{
                      textTransform: "capitalize",
                      color: "#FFf",
                      border: "none",
                      padding: "0.5rem 2rem",
                      borderRadius: "10px",
                    }}
                  >
                    delete
                  </button>
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CompletedSessions;
