import React, { useState } from "react";
import styled from "styled-components";
import LanguageSelectionModal from "./LanguageSelectionModal/LanguageSelectionModal";

import "..//Header.css";
function StartSessionBtn() {
  const [showOverlay, setShowOverlay] = useState(false);

  const hideOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <>
      {showOverlay && <div className="backgroundOverlay"></div>}
      {showOverlay && (
        <LanguageSelectionModal
          setShowOverlay={setShowOverlay}
          gotoParent={hideOverlay}
        />
      )}
      <button onClick={() => setShowOverlay(true)} className="startSession">
        + start new session
      </button>
    </>
  );
}

export default StartSessionBtn;
