// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlayResetpass {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11111;
}
.modalBox {
  position: absolute;
  top: 22%;
  width: 40%;
  left: 20%;
  margin-left: 10%;
padding: 3rem 0;
  bottom: 0;
  background-color: white;
  z-index: 666666;
  box-shadow: 0px 4px 25px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.modalBox .modalboxWrapper{
  width: 79%;
  margin: 0 auto;
}
.modalBox .info1{
  font-size: 1.1rem;
text-align: center;
  font-weight: 500;
}
.modalBox .info2{
  font-size: 1.1rem;
text-align: center;

color: #6B6B6B;;
}
 .modalBox .btn1{
  padding: 1rem 0;
  width: 50%;
  background-color: black;
  text-decoration: none;
  color: #FFF;
  border-radius: 10px;
  -webkit-border-radius: 10PX;
  -moz-border-radius: 10PX;
  -ms-border-radius: 10PX;
  -o-border-radius: 10PX;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/ResetPass/resetpass.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,aAAa;EACb,YAAY;EACZ,oCAAoC;EACpC,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,SAAS;EACT,gBAAgB;AAClB,eAAe;EACb,SAAS;EACT,uBAAuB;EACvB,eAAe;EACf,+CAA+C;EAC/C,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,iBAAiB;AACnB,kBAAkB;EAChB,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB,kBAAkB;;AAElB,cAAc;AACd;CACC;EACC,eAAe;EACf,UAAU;EACV,uBAAuB;EACvB,qBAAqB;EACrB,WAAW;EACX,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".overlayResetpass {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 11111;\n}\n.modalBox {\n  position: absolute;\n  top: 22%;\n  width: 40%;\n  left: 20%;\n  margin-left: 10%;\npadding: 3rem 0;\n  bottom: 0;\n  background-color: white;\n  z-index: 666666;\n  box-shadow: 0px 4px 25px 5px rgba(0, 0, 0, 0.1);\n  border-radius: 20px;\n}\n.modalBox .modalboxWrapper{\n  width: 79%;\n  margin: 0 auto;\n}\n.modalBox .info1{\n  font-size: 1.1rem;\ntext-align: center;\n  font-weight: 500;\n}\n.modalBox .info2{\n  font-size: 1.1rem;\ntext-align: center;\n\ncolor: #6B6B6B;;\n}\n .modalBox .btn1{\n  padding: 1rem 0;\n  width: 50%;\n  background-color: black;\n  text-decoration: none;\n  color: #FFF;\n  border-radius: 10px;\n  -webkit-border-radius: 10PX;\n  -moz-border-radius: 10PX;\n  -ms-border-radius: 10PX;\n  -o-border-radius: 10PX;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
