import axios from "axios";

// const token = localStorage.getItem("token1");

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_URL}`,

  // headers: {
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${token}`,
  // },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token1");

    if (token) {
      config.headers = {
        Acccept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_HOST_URL,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
