import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors";
import axios from "axios";
function NewPassword() {
  // const navigate = useNavigate(null);
  const [msg, setMsg] = useState("");
  const schema = yup.object().shape({
    password: yup.string().min(3).required(),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const submit = (data, e) => {
    e.preventDefault();
    console.log(data);
    console.log("as")
    var data1 = JSON.stringify({
      password: data.password,
      confirmPassword: data.confirmPassword,
      verificationToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImVtYWlsIjoic2hheWFuYWtodGFyQGdtYWlsLmNvbSJ9LCJpYXQiOjE2NjAxNDQ5MTYsImV4cCI6MTY2MDE0NTgxNn0.yvxvLo3MHz7w_DLHrGfcltMrEcwZE95xwiIUP2fAMgw",
    });

    var config = {
      method: "post",
      url: `http://${process.env.REACT_APP_HOST_URL}/api/reset-password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data1,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.status) {
          setMsg(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="NewPassword">
      <form
        className="LogintextField"
        action="#"
        onSubmit={handleSubmit(submit)}
      >
        <div className="formFields">
          {" "}
          <div className="textField mb-2">
            {" "}
            <input
              //   style={errors.email ? { border: "1px solid #ed1c24" } : {}}
              {...register("password")}
              type="password"
              placeholder="New Password"
            />
          </div>
          <div className="textField">
            {" "}
            <input
              {...register("confirmPassword")}
              //   style={errors.password ? { border: "1px solid #ed1c24" } : {}}
              type="password"
              placeholder="Confirm Password"
            />
          </div>
          <p style={{ color: "red", fontSize: "0.8rem" }}>
            {errors.confirmPassword && "Password should match"}
            {msg}
          </p>
          <button className="submit">Save new password</button>
        </div>
      </form>
    </div>
  );
}

export default NewPassword;
