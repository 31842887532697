// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableQuestionWrapper p {
  color: #000;
  font-weight: 400;
  text-transform: capitalize;

  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
}

.table th {
  font-family: "Poppins";

  font-weight: 500;
  font-size: 16px;

  /* identical to box height */
  padding: 0.7rem 1rem !important;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;

  padding-right: 0.2rem !important;
}
thead tr {
  background-color: #ed1c24;
}
td {
padding:1rem 0 !important;

  text-transform: capitalize;

text-align: center;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}
.inputField{
font-size: 2.2rem;
text-align: center;


}
.cross {
  text-align: center;
  padding-left: 0 !important;
}
.cross img {
  width: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/patientform/questionTable/tableques.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,0BAA0B;;EAE1B,qBAAqB;EACrB,iBAAiB;EACjB,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;;EAEtB,gBAAgB;EAChB,eAAe;;EAEf,4BAA4B;EAC5B,+BAA+B;EAC/B,cAAc;EACd,kCAAkC;EAClC,0BAA0B;;EAE1B,gCAAgC;AAClC;AACA;EACE,yBAAyB;AAC3B;AACA;AACA,yBAAyB;;EAEvB,0BAA0B;;AAE5B,kBAAkB;EAChB,iBAAiB;EACjB,kCAAkC;AACpC;AACA;AACA,iBAAiB;AACjB,kBAAkB;;;AAGlB;AACA;EACE,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf","sourcesContent":[".tableQuestionWrapper p {\n  color: #000;\n  font-weight: 400;\n  text-transform: capitalize;\n\n  margin-bottom: 1.5rem;\n  font-size: 0.9rem;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.table th {\n  font-family: \"Poppins\";\n\n  font-weight: 500;\n  font-size: 16px;\n\n  /* identical to box height */\n  padding: 0.7rem 1rem !important;\n  color: #ffffff;\n  font-family: \"Poppins\", sans-serif;\n  text-transform: capitalize;\n\n  padding-right: 0.2rem !important;\n}\nthead tr {\n  background-color: #ed1c24;\n}\ntd {\npadding:1rem 0 !important;\n\n  text-transform: capitalize;\n\ntext-align: center;\n  font-size: 1.2rem;\n  font-family: \"Poppins\", sans-serif;\n}\n.inputField{\nfont-size: 2.2rem;\ntext-align: center;\n\n\n}\n.cross {\n  text-align: center;\n  padding-left: 0 !important;\n}\n.cross img {\n  width: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
