import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import "./languageconversion.css";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from "@mui/material";
import { LanguageSelectionApi } from "../../apis/services/LanguageSelectionapi";
import arrow from "..//..//assets/doublearrows.png";

const LanguageConversion = ({ flag }) => {
  const [languages, setLanguages] = useState([]);
  const [patientlanguage, setpatientLanguage] = useState("English");
  const [practitionerlanguage, setpractitionerLanguage] = useState("English");

  const { LogOut } = useContext(AuthContext);

  const handleChange = (event) => {
    setpatientLanguage(event.target.value);
    localStorage.setItem("patientLang", event.target.value);
  };

  const handleChange1 = (event) => {
    setpractitionerLanguage(event.target.value);
    localStorage.setItem("practitionerLang", event.target.value);
  };

  useEffect(() => {
    const getlanguages = async () => {
      try {
        const response = await LanguageSelectionApi();
        setLanguages(response.data.data);
        console.log(response);
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 401) {
          LogOut();
        }
      }
    };

    getlanguages();
  }, []);
  useEffect(() => {
    if (flag) {
      console.log(practitionerlanguage, patientlanguage);

      localStorage.setItem("practitionerLang", practitionerlanguage);
      localStorage.setItem("patientLang", patientlanguage);
    } else {
      setpatientLanguage(localStorage.getItem("patientLang"));
      setpractitionerLanguage(localStorage.getItem("practitionerLang"));
    }
  }, [practitionerlanguage, patientlanguage]);
  return (
    <div className="LanguageConversion py-4">
      <div
        className="languageconversionWrapper"
        style={!flag ? { margin: "0 auto", width: "100%" } : {}}
      >
        <div className="d-flex justify-content-between">
          <p className="language"> Patient language</p>
          <p className="language"> Practitioner language</p>
        </div>
        <div className="conversionComponent px-3 py-2">
          <div>
            {flag ? (
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={patientlanguage}
                  onChange={handleChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        zIndex: 200,
                      },
                    },
                  }}
                >
                  {languages.map((language, index) => (
                    <MenuItem key={index} value={language.name}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField value={patientlanguage} />
            )}
          </div>

          {/* <div className="iconsConversion">
            <img className="arrow" src={arrow} />
          </div> */}
          <div className="paractitionerLanguage">
            {" "}
            {flag ? (
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={practitionerlanguage}
                  onChange={handleChange1}
                >
                  {languages.map((language, index) => (
                    <MenuItem key={index} value={language.name}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField value={practitionerlanguage} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageConversion;
