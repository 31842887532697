import React, {
  useState,
  useLayoutEffect,
  useContext,
  useRef,
  useEffect,
} from "react";

import { UserResponseContext } from "../patientform/PatientForm/Questions";
import marker from "./../../assets/marker.png";

import Draggable from "react-draggable";

import "./Painscale.css";

function PainScale({ quesId, painScaleRes, view }) {
  const { userresponse, setuserResponse } = useContext(UserResponseContext);
  // console.log(quesId);

  const [SliderValue, setSliderValue] = useState(1);

  // const [SliderMovement, setSliderMovement] = useState(0);
  // const [movement, setMovement] = useState("");
  // const [transformR, setTransformR] = useState(false);
  // const [xValue, setXvalue] = useState();
  // const [transformX, setTransformX] = useState(false);
  const [sliderWidth, setSliderWidth] = useState();
  const [size, setSize] = useState();
  // const [count, setCount] = useState(1);
  const painScaleRef = useRef(null);

  useEffect(() => {
    setuserResponse({ ...userresponse, [quesId]: SliderValue });
  }, [SliderValue]);

  useEffect(() => {
    // getting width of bar
    // function updateSize() {
    let sliderwidth = painScaleRef.current.offsetWidth;
    let width = painScaleRef.current.offsetWidth / 10;
    setSize(width);
    setSliderWidth(sliderwidth);
    // console.log(width,sliderwidth)
    // }
    // window.addEventListener("resize", updateSize);
    // updateSize();
    // return () => window.removeEventListener("resize", updateSize);
  }, []);

  // useEffect(() => {
  //   if (painScaleRes && painScaleRes > 0) {
  //     setXvalue((painScaleRes - 1) * size);
  //   } else {
  //     setXvalue(1);
  //   }
  // }, [size, painScaleRes]);

  function handleDrag(e, elem) {
    console.log(elem.x);
    // console.log((painScaleRes - 1) * size);

    setSliderValue(elem?.x);

    // let sliderValue = 1;

    // if (elem.lastX > elem.x) {
    //   // setuserResponse({ ...userresponse, [quesId]: sliderValue - 1 });
    //   setSliderValue(SliderValue - 1);
    // } else if (elem.lastX < elem.x) {
    //   // setuserResponse({ ...userresponse, [quesId]: sliderValue + 1 });
    //   setSliderValue(SliderValue + 1);
    // }

    // let deltaX = elem.x * count;
    // // console.log("DSds" + deltaX);
    // // // let positionX = elem.x;
    // // setCount(count + 1);
    // console.log(deltaX);
    // let ans = Math.round(deltaX / size + 1);
    // setSliderMovement(deltaX);
    // setSliderValue(ans);
    // if (deltaX > ans * deltaX) {
    //   setIsDrag(true);
    // } else {
    //   // console.log(elem.x);
    //   // // console.log(ans);
    //   // console.log(ans);
    // }
    // setCount(count + 1);
    // if (SliderValue == 10) {
    //   setc;
    // }
  }

  // function handleStop() {
  //   let parentElement = dragElem.parentElement;
  //   let offsetparent = parentElement.getBoundingClientRect();
  //   let offsetChild = dragElem.getBoundingClientRect();

  //   let totalDistance = offsetChild.left - offsetparent.left;

  //   let ans = Math.round(totalDistance / size + 1);

  //   setSliderValue(ans);
  // }
  // function getElement(e) {
  //   setDragElement(e.target);
  // }
  // function getPostion(e) {
  //   // const elemId = e.target.id;

  //   // // const time =
  //   // //   elem.parentElement.parentElement.previousElementSibling.innerText; // geting element to check duration i.e week,month or days

  //   // console.log(elemId);
  //   let clickedNumber = Number(e.target.previousElementSibling.innerText);

  //   if (clickedNumber < SliderValue) {
  //     // console.log("paellts"+(pallets.length-1)*30)

  //     //   currentPos=((pallets.length-1)*30)- positon
  //     if (SliderValue > 1) {
  //       setTransformX(false);
  //     } else {
  //       setTransformX(true);
  //     }
  //     currentPos = transformX
  //       ? (clickedNumber - 1) * size
  //       : SliderMovement - (clickedNumber - 1) * size;

  //     // console.log(currentPos);

  //     setMovement(currentPos);

  //     // setValue(clickedNumber);

  //     // resetPos = (clickedNumber - 1) * size;
  //     setSliderValue(clickedNumber);

  //     setuserResponse({ ...userresponse, [quesId]: clickedNumber });
  //     // setPosition(resetPos);
  //   } else if (clickedNumber > SliderValue) {
  //     // setTransformX(true);
  //     //  let distanceDiff2=clickedNumber-SliderValue;
  //     currentPos = SliderMovement - (clickedNumber - 1) * size;
  //     // transformX
  //     //   ? (clickedNumber - SliderValue) * 30
  //     //   : -(SliderMovement - (clickedNumber - 1) * 30);
  //     //   console.log("move right side"+moveRightSide)
  //     // //   let currentPos2=value*30
  //     // console.log("move right"+moveRightSide)

  //     // setMovement(moveRightSide)
  //     setMovement(currentPos);

  //     // setValue(clickedNumber);
  //     setSliderValue(clickedNumber);
  //     // setCount(clickedNumber);
  //     // resetPos = (clickedNumber - 1) * size;

  //     // setPosition(resetPos);

  //     setuserResponse({ ...userresponse, [quesId]: clickedNumber });
  //   }
  // }

  // useEffect(()=>{
  //   function handleResize(){
  // setpalleteWidth(getWidthofelem())}
  //  window.addEventListener('resize', handleResize);
  //  return () => window.removeEventListener('resize', handleResize);

  // })
  //   // getting width of colorpallete as screen size changes

  // function  getWidthofelem(){
  //   const distance=(getWidth.current.offsetWidth)/10
  //   console.log(getWidth.current.offsetWidth)
  //   setpalleteWidth(distance)

  // }

  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // console.log("XVALUE", view, xValue, painScaleRes);

  return (
    <div className="rangeSlider position-relative mb-5">
      <div
        ref={painScaleRef}
        className="sliderColorPallete  d-flex justify-content-between"
      >
        {array.map((value, index) => {
          return (
            <div className="ColorBox">
              <p>{value}</p>
              <div className={`item${value} item`}></div>
            </div>
          );
        })}
      </div>
      <div
        className="dragcontainer position-relative"
        style={{ width: sliderWidth - size + 30, margin: "0 auto" }}
      >
        {view ? (
          painScaleRes && (
            <Draggable
              // onMouseDown={getElement}
              onDrag={handleDrag}
              draggable={false}
              handle=".handle"
              // onStop={handleStop}
              defaultPosition={{ x: painScaleRes, y: 0 }}
              grid={[size]}
              bounds="parent"
              axis="x"
            >
              <img
                className="handle"
                src={marker}
                alt=""
                draggable={false}
                // style={
                //   {
                //     // position: "absolute",
                //     // left: transformX
                //     //   ? `${(painScaleRes - 1) * size}`
                //     //   : `${(painScaleRes - 1) * size}`,
                //   }
                // }
              />
            </Draggable>
          )
        ) : (
          <Draggable
            // onMouseDown={getElement}
            onDrag={handleDrag}
            draggable={false}
            handle=".handle"
            // onStop={handleStop}
            defaultPosition={{ x: 0, y: 0 }}
            grid={[size]}
            bounds="parent"
            axis="x"
          >
            <img
              className="handle"
              src={marker}
              alt=""
              draggable={false}
              // style={
              //   {
              //     // position: "absolute",
              //     // left: transformX
              //     //   ? `${(painScaleRes - 1) * size}`
              //     //   : `${(painScaleRes - 1) * size}`,
              //   }
              // }
            />
          </Draggable>
        )}
      </div>

      {/* using dynamic values in grid and bounds as width of pallete changes width screen size */}
    </div>
  );
}

export default PainScale;
