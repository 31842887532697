import React, { useState } from "react";
import "./resetpass.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Link as button } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
function ResetPass() {
  const navigate = useNavigate(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [verificationemail, setVerificationEmail] = useState("");
  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClick = () => {
    navigate("/newpassword");
  };
  const submit = (data) => {
    setShowOverlay(true);
    setVerificationEmail(data.email);
    var data1 = JSON.stringify({
      email: data.email,
    });

    var config = {
      method: "post",
      url: `http://${process.env.REACT_APP_HOST_URL}/api/forgot-password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data1,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
  };
  return (
    <div>
      {showOverlay && <div className="overlayResetpass"></div>}
      {showOverlay && (
        <div className="modalBox">
          <div className="modalboxWrapper">
            <div className="mailicon text-center mb-3">
              <svg
                width="80"
                height="80"
                viewBox="0 0 127 127"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.3"
                  cx="63.5"
                  cy="63.5"
                  r="63.5"
                  fill="#ED1C24"
                />
                <circle
                  opacity="0.3"
                  cx="63.5"
                  cy="63.5"
                  r="49.5"
                  fill="#ED1C24"
                />
                <path
                  d="M86 52.1111C84 50.5555 66.3889 39.3888 65.6111 38.9444C65 38.6111 63.8332 38 63 38C62.1669 38 61 38.6111 60.3889 38.9444C59.6112 39.3889 42 50.5555 40 52.1111C38 53.667 38 55.6112 38 55.6666V88H88V55.7222C88 55.6664 88 53.6667 86 52.1109V52.1111ZM85.9446 83.2778C87.8893 84.8333 87.8893 87.7777 87.8893 87.7777C87.8893 87.4444 87.6672 85.6665 85.9446 84.2776C84 82.722 66.5002 71.5553 65.7224 71.1109C65.667 71.0555 65.6112 71.0555 65.5558 70.9997C65.5003 70.9443 65.4445 70.9443 65.3891 70.8885C64.7226 70.5552 63.8336 70.1107 63.1671 70.1107C62.4447 70.1107 61.5003 70.5552 60.8338 70.8885C60.7225 70.9439 60.6671 70.9997 60.5559 71.0551C59.7781 71.4996 42.2781 82.6662 40.3337 84.2218C38.389 85.7773 38.167 87.7219 38.167 87.7219C38.167 87.7219 38.3336 84.7774 40.3337 83.222C42.167 81.7776 57.0559 72.222 60.0559 70.3886C53.7222 65.8891 44.0549 57.4442 42.3882 56.6664C40.5549 55.8332 39.0547 55.6666 38.3328 55.6666C38.6106 55 39.1105 54.2222 39.9995 53.4999C41.9995 51.9444 59.6107 40.7777 60.3884 40.3332C61.055 39.9442 62.1664 39.3888 62.9995 39.3888C63.8327 39.3888 64.9995 40 65.6107 40.3332C66.3884 40.7777 83.9996 51.9444 85.9996 53.4999C86.8885 54.2223 87.3885 55 87.6663 55.6666C86.944 55.6666 85.4443 55.8332 83.6663 56.6664C81.9442 57.4441 72.3885 65.7775 66.1108 70.3886C68.8885 72.1107 84.1108 81.8331 85.9441 83.2775L85.9446 83.2778ZM60.1668 61L65.8336 52.0555L58.0002 59.1112L54.2226 55.8335L68.5559 48.7778L64.0002 64.1667L60.1668 61Z"
                  fill="black"
                />
              </svg>
            </div>
            <p className="info1 mb-1">Check your email</p>
            <p className="info2 mb-1">We sent reset password link to</p>
            <p
              className="sentEmail text-center mt-4"
              style={{ color: "#6B6B6B" }}
            >
              {verificationemail}
            </p>
            <div className="actionBtn text-center">
              <button onClick={handleClick} className="btn1">
                Open email app
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="ResetPass position-relative">
        <form
          className="LogintextField"
          action="#"
          onSubmit={handleSubmit(submit)}
        >
          <div className="formFields">
            {" "}
            <div className="textField">
              {" "}
              <input
                style={errors.email ? { border: "1px solid #ed1c24" } : {}}
                {...register("email")}
                type="text"
                placeholder="Enter recovery mail"
              />
            </div>
            <div className="d-flex mt-2 justify-content-between">
              <div className="checkbox ">
                <p className="text-capitalize mb-0">
                  A recovery link will be sent to this email.
                </p>
              </div>
            </div>
            <button className="submit">send link</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPass;
