import React ,{createContex}from "react";
import { createContext } from "react";


export const  Mycontext=createContext()
function Main(props) {
  const { childComp1, childComp2 } = props;
  const [value1, setValue1] = React.useState([20, 37]);
  return (
    <Mycontext.Provider value={{value1, setValue1}}>    
    
    <div>
    {childComp2}
    {childComp1}
  </div>
</Mycontext.Provider>

  );
}

export default Main;
