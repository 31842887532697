import React, { useEffect, useState } from "react";
import "./tableques.css";
import cross from "./../../..//assets//close.png";
import Question from "../Question/Question";

function TableQuestion({
  quesId,
  userresponse,
  setuserResponse,
  tableDataObj,
  tableOptions,
}) {
  const [tableData, setTableData] = useState({
    aspiring: { iTake: "", iAmAllergic: "" },
    fluidWater: { iTake: "", iAmAllergic: "" },
    seizure: { iTake: "", iAmAllergic: "" },
    painMed: { iTake: "", iAmAllergic: "" },
    penicillin: { iTake: "", iAmAllergic: "" },
    anxietyMed: { iTake: "", iAmAllergic: "" },
    bloodThinner: { iTake: "", iAmAllergic: "" },
    heartMed: { iTake: "", iAmAllergic: "" },
    erectileMed: { iTake: "", iAmAllergic: "" },
    sulfa: { iTake: "", iAmAllergic: "" },
    soy: { iTake: "", iAmAllergic: "" },
    antibiotics: { iTake: "", iAmAllergic: "" },
    latex: { iTake: "", iAmAllergic: "" },
    nutAllergey: { iTake: "", iAmAllergic: "" },
    morphine: { iTake: "", iAmAllergic: "" },
  });

  useEffect(() => {
    console.log(tableDataObj);
    if (tableDataObj) {
      setTableData({ ...tableDataObj });
    }
  }, [tableDataObj]);

  console.log(tableData);

  useEffect(() => {
    setuserResponse({ ...userresponse, [quesId]: JSON.stringify(tableData) });
  }, [tableData]);

  function showMark(e, option, section) {
    console.log(e, option, section);
    if (section === "iTake") {
      updateTakeOption(option);
    } else {
      updateAllergicOption(option);
    }

    setTableData({ ...tableData });
    // if (e.target.innerText != "X") {
    //   e.target.innerText = "X";
    // } else {
    //   e.target.innerHTML = "&#10003";
    // }
  }

  console.log("from-table", quesId);

  const updateTakeOption = (option) => {
    if (option === "aspiring") {
      if (
        tableData.aspiring.iTake === "" ||
        tableData.aspiring.iTake === false
      ) {
        tableData.aspiring.iTake = true;
      } else {
        tableData.aspiring.iTake = false;
      }
    } else if (option === "fluidWater") {
      if (
        tableData.fluidWater.iTake === "" ||
        tableData.fluidWater.iTake === false
      ) {
        tableData.fluidWater.iTake = true;
      } else {
        tableData.fluidWater.iTake = false;
      }
    } else if (option === "seizure") {
      if (tableData.seizure.iTake === "" || tableData.seizure.iTake === false) {
        tableData.seizure.iTake = true;
      } else {
        tableData.seizure.iTake = false;
      }
    } else if (option === "painMed") {
      if (tableData.painMed.iTake === "" || tableData.painMed.iTake === false) {
        tableData.painMed.iTake = true;
      } else {
        tableData.painMed.iTake = false;
      }
    } else if (option === "penicillin") {
      if (
        tableData.penicillin.iTake === "" ||
        tableData.penicillin.iTake === false
      ) {
        tableData.penicillin.iTake = true;
      } else {
        tableData.penicillin.iTake = false;
      }
    } else if (option === "anxietyMed") {
      if (
        tableData.anxietyMed.iTake === "" ||
        tableData.anxietyMed.iTake === false
      ) {
        tableData.anxietyMed.iTake = true;
      } else {
        tableData.anxietyMed.iTake = false;
      }
    } else if (option === "bloodThinner") {
      if (
        tableData.bloodThinner.iTake === "" ||
        tableData.bloodThinner.iTake === false
      ) {
        tableData.bloodThinner.iTake = true;
      } else {
        tableData.bloodThinner.iTake = false;
      }
    } else if (option === "heartMed") {
      if (
        tableData.heartMed.iTake === "" ||
        tableData.heartMed.iTake === false
      ) {
        tableData.heartMed.iTake = true;
      } else {
        tableData.heartMed.iTake = false;
      }
    } else if (option === "erectileMed") {
      if (
        tableData.erectileMed.iTake === "" ||
        tableData.erectileMed.iTake === false
      ) {
        tableData.erectileMed.iTake = true;
      } else {
        tableData.erectileMed.iTake = false;
      }
    } else if (option === "sulfa") {
      if (tableData.sulfa.iTake === "" || tableData.sulfa.iTake === false) {
        tableData.sulfa.iTake = true;
      } else {
        tableData.sulfa.iTake = false;
      }
    } else if (option === "soy") {
      if (tableData.soy.iTake === "" || tableData.soy.iTake === false) {
        tableData.soy.iTake = true;
      } else {
        tableData.soy.iTake = false;
      }
    } else if (option === "antibiotics") {
      if (
        tableData.antibiotics.iTake === "" ||
        tableData.antibiotics.iTake === false
      ) {
        tableData.antibiotics.iTake = true;
      } else {
        tableData.antibiotics.iTake = false;
      }
    } else if (option === "latex") {
      if (tableData.latex.iTake === "" || tableData.latex.iTake === false) {
        tableData.latex.iTake = true;
      } else {
        tableData.latex.iTake = false;
      }
    } else if (option === "nutAllergey") {
      if (
        tableData.nutAllergey.iTake === "" ||
        tableData.nutAllergey.iTake === false
      ) {
        tableData.nutAllergey.iTake = true;
      } else {
        tableData.nutAllergey.iTake = false;
      }
    } else if (option === "morphine") {
      if (
        tableData.morphine.iTake === "" ||
        tableData.morphine.iTake === false
      ) {
        tableData.morphine.iTake = true;
      } else {
        tableData.morphine.iTake = false;
      }
    }
  };

  const updateAllergicOption = (option) => {
    if (option === "aspiring") {
      if (
        tableData.aspiring.iAmAllergic === "" ||
        tableData.aspiring.iAmAllergic === false
      ) {
        console.log("cheking data");

        tableData.aspiring.iAmAllergic = true;
      } else {
        tableData.aspiring.iAmAllergic = false;
      }
    } else if (option === "fluidWater") {
      if (
        tableData.fluidWater.iAmAllergic === "" ||
        tableData.fluidWater.iAmAllergic === false
      ) {
        tableData.fluidWater.iAmAllergic = true;
      } else {
        tableData.fluidWater.iAmAllergic = false;
      }
    } else if (option === "seizure") {
      if (
        tableData.seizure.iAmAllergic === "" ||
        tableData.seizure.iAmAllergic === false
      ) {
        tableData.seizure.iAmAllergic = true;
      } else {
        tableData.seizure.iAmAllergic = false;
      }
    } else if (option === "painMed") {
      if (
        tableData.painMed.iAmAllergic === "" ||
        tableData.painMed.iAmAllergic === false
      ) {
        tableData.painMed.iAmAllergic = true;
      } else {
        tableData.painMed.iAmAllergic = false;
      }
    } else if (option === "penicillin") {
      if (
        tableData.penicillin.iAmAllergic === "" ||
        tableData.penicillin.iAmAllergic === false
      ) {
        tableData.penicillin.iAmAllergic = true;
      } else {
        tableData.penicillin.iAmAllergic = false;
      }
    } else if (option === "anxietyMed") {
      if (
        tableData.anxietyMed.iAmAllergic === "" ||
        tableData.anxietyMed.iAmAllergic === false
      ) {
        tableData.anxietyMed.iAmAllergic = true;
      } else {
        tableData.anxietyMed.iAmAllergic = false;
      }
    } else if (option === "bloodThinner") {
      if (
        tableData.bloodThinner.iAmAllergic === "" ||
        tableData.bloodThinner.iAmAllergic === false
      ) {
        tableData.bloodThinner.iAmAllergic = true;
      } else {
        tableData.bloodThinner.iAmAllergic = false;
      }
    } else if (option === "heartMed") {
      if (
        tableData.heartMed.iAmAllergic === "" ||
        tableData.heartMed.iAmAllergic === false
      ) {
        tableData.heartMed.iAmAllergic = true;
      } else {
        tableData.heartMed.iAmAllergic = false;
      }
    } else if (option === "erectileMed") {
      if (
        tableData.erectileMed.iAmAllergic === "" ||
        tableData.erectileMed.iAmAllergic === false
      ) {
        tableData.erectileMed.iAmAllergic = true;
      } else {
        tableData.erectileMed.iAmAllergic = false;
      }
    } else if (option === "sulfa") {
      if (
        tableData.sulfa.iAmAllergic === "" ||
        tableData.sulfa.iAmAllergic === false
      ) {
        tableData.sulfa.iAmAllergic = true;
      } else {
        tableData.sulfa.iAmAllergic = false;
      }
    } else if (option === "soy") {
      if (
        tableData.soy.iAmAllergic === "" ||
        tableData.soy.iAmAllergic === false
      ) {
        tableData.soy.iAmAllergic = true;
      } else {
        tableData.soy.iAmAllergic = false;
      }
    } else if (option === "antibiotics") {
      if (
        tableData.antibiotics.iAmAllergic === "" ||
        tableData.antibiotics.iAmAllergic === false
      ) {
        tableData.antibiotics.iAmAllergic = true;
      } else {
        tableData.antibiotics.iAmAllergic = false;
      }
    } else if (option === "latex") {
      if (
        tableData.latex.iAmAllergic === "" ||
        tableData.latex.iAmAllergic === false
      ) {
        tableData.latex.iAmAllergic = true;
      } else {
        tableData.latex.iAmAllergic = false;
      }
    } else if (option === "nutAllergey") {
      if (
        tableData.nutAllergey.iAmAllergic === "" ||
        tableData.nutAllergey.iAmAllergic === false
      ) {
        tableData.nutAllergey.iAmAllergic = true;
      } else {
        tableData.nutAllergey.iAmAllergic = false;
      }
    } else if (option === "morphine") {
      if (
        tableData.morphine.iAmAllergic === "" ||
        tableData.morphine.iAmAllergic === false
      ) {
        tableData.morphine.iAmAllergic = true;
      } else {
        tableData.morphine.iAmAllergic = false;
      }
    }
  };

  return (
    <div className="tableQuestion" style={{ marginTop: "20px" }}>
      <div className="tableQuestionWrapper">
        <div className="table-responsive mb-4">
          <table class="table table-bordered w-100">
            <thead>
              <tr>
                <th>name</th>
                <th>i take</th>
                <th>i am allergic</th>
                <th>name</th>
                <th>i take</th>
                <th>i am allergic</th>
                <th>name</th>
                <th>i take</th>
                <th>i am allergic</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{tableOptions[0]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "aspiring", "iTake")}
                >
                  {tableData.aspiring.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.aspiring.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "aspiring", "iAmAllergic")}
                >
                  {tableData.aspiring.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.aspiring.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[1]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "anxietyMed", "iTake")}
                >
                  {tableData.anxietyMed.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.anxietyMed.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "anxietyMed", "iAmAllergic")}
                >
                  {tableData.anxietyMed.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.anxietyMed.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[2]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "soy", "iTake")}
                >
                  {tableData.soy.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.soy.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "soy", "iAmAllergic")}
                >
                  {tableData.soy.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.soy.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
              </tr>
              <tr>
                <td>{tableOptions[3]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "fluidWater", "iTake")}
                >
                  {tableData.fluidWater.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.fluidWater.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "fluidWater", "iAmAllergic")}
                >
                  {tableData.fluidWater.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.fluidWater.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[4]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "bloodThinner", "iTake")}
                >
                  {tableData.bloodThinner.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.bloodThinner.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "bloodThinner", "iAmAllergic")}
                >
                  {tableData.bloodThinner.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.bloodThinner.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[5]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "antibiotics", "iTake")}
                >
                  {tableData.antibiotics.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.antibiotics.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "antibiotics", "iAmAllergic")}
                >
                  {tableData.antibiotics.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.antibiotics.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
              </tr>
              <tr>
                <td>{tableOptions[6]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "seizure", "iTake")}
                >
                  {tableData.seizure.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.seizure.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "seizure", "iAmAllergic")}
                >
                  {tableData.seizure.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.seizure.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[7]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "heartMed", "iTake")}
                >
                  {tableData.heartMed.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.heartMed.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "heartMed", "iAmAllergic")}
                >
                  {tableData.heartMed.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.heartMed.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[8]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "latex", "iTake")}
                >
                  {tableData.latex.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.latex.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "latex", "iAmAllergic")}
                >
                  {tableData.latex.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.latex.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
              </tr>
              <tr>
                <td>{tableOptions[9]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "painMed", "iTake")}
                >
                  {tableData.painMed.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.painMed.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "painMed", "iAmAllergic")}
                >
                  {tableData.painMed.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.painMed.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[10]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "erectileMed", "iTake")}
                >
                  {tableData.erectileMed.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.erectileMed.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "erectileMed", "iAmAllergic")}
                >
                  {tableData.erectileMed.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.erectileMed.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[11]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "nutAllergey", "iTake")}
                >
                  {tableData.nutAllergey.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.nutAllergey.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "nutAllergey", "iAmAllergic")}
                >
                  {tableData.nutAllergey.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.nutAllergey.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
              </tr>
              <tr>
                <td>{tableOptions[12]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "penicillin", "iTake")}
                >
                  {tableData.penicillin.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.penicillin.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "penicillin", "iAmAllergic")}
                >
                  {tableData.penicillin.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.penicillin.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[13]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "sulfa", "iTake")}
                >
                  {tableData.sulfa.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.sulfa.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "sulfa", "iAmAllergic")}
                >
                  {tableData.sulfa.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.sulfa.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td>{tableOptions[14]}</td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "morphine", "iTake")}
                >
                  {tableData.morphine.iTake ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.morphine.iTake === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
                <td
                  class="inputField p-0"
                  onClick={(e) => showMark(e, "morphine", "iAmAllergic")}
                >
                  {tableData.morphine.iAmAllergic ? (
                    <img
                      style={{ height: "50px" }}
                      src="/images/check-lg.svg"
                    />
                  ) : tableData.morphine.iAmAllergic === "" ? (
                    ""
                  ) : (
                    "X"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <Question
        ques5_1="Have you used any illegal drugs today?"
        ques5_2="Have you consumed any alcohol today?"
        value={["yes", "no"]}
      />
      <Question
        ques5_1="Have you had a fever??"
        ques5_2="Have you traveled outside the country recently?"
        value={["yes", "no"]}
      /> */}
    </div>
  );
}

export default TableQuestion;
