// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexColors .item {
  height: 40px;
  background-color: #ed1c24;
  color: white;
}
/* .sliderParent{
  width: 100%;
} */
label {
  text-transform: capitalize;
}
.questiontextboxWrapperResponsive {
  display: none;
}
.handle {
  width: 40px;
}
.dragContainer{


  margin: 0 auto;
}
.ColorPallete .duration{

  margin-right: 20px;
}

.sliderQues{
  width: 90%;
  margin: 0 auto;
}


@media (max-width: 760px) {
  .questiontextboxWrapper {
    display: none;
  }
  .questiontextboxWrapperResponsive {
    display: block;
  }
 
}
`, "",{"version":3,"sources":["webpack://./src/components/patientform/WomenPatient/colorpallets/ColorPallets.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;AACd;AACA;;GAEG;AACH;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;;;EAGE,cAAc;AAChB;AACA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;;AAGA;EACE;IACE,aAAa;EACf;EACA;IACE,cAAc;EAChB;;AAEF","sourcesContent":[".flexColors .item {\n  height: 40px;\n  background-color: #ed1c24;\n  color: white;\n}\n/* .sliderParent{\n  width: 100%;\n} */\nlabel {\n  text-transform: capitalize;\n}\n.questiontextboxWrapperResponsive {\n  display: none;\n}\n.handle {\n  width: 40px;\n}\n.dragContainer{\n\n\n  margin: 0 auto;\n}\n.ColorPallete .duration{\n\n  margin-right: 20px;\n}\n\n.sliderQues{\n  width: 90%;\n  margin: 0 auto;\n}\n\n\n@media (max-width: 760px) {\n  .questiontextboxWrapper {\n    display: none;\n  }\n  .questiontextboxWrapperResponsive {\n    display: block;\n  }\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
