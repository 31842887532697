import { useState, useEffect } from "react";
import "./textbox.css";
// import { AiOutlineCalendar } from "react-icons/ai";
import { Mycontext } from "../QuestionTextBox";

import { useContext } from "react";
import { UserResponseContext } from "../../PatientForm/Questions";
function Textbox({ quesId, name, nameRes, howLongAgoRes }) {
  const { patientName, setPatientName, userresponse, setuserResponse } =
    useContext(UserResponseContext);

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    name && setuserResponse({ ...userresponse, [quesId]: patientName });
  }, [patientName]);

  // useEffect(() => {
  //   !name && setuserResponse({ ...userresponse, [quesId]: answer });
  // }, [answer]);

  // useEffect(() => {
  //   nameRes && setPatientName(nameRes);
  // }, [nameRes]);

  // useEffect(() => {
  //   howLongAgoRes && setAnswer(howLongAgoRes);
  // }, [howLongAgoRes]);

  return (
    <div className="textbox">
      <div class="form-check">
        {name ? (
          <input
            placeholder="Your Name"
            type="text"
            value={nameRes ? nameRes : patientName}
            onChange={(e) => setPatientName(e.target.value)}
          />
        ) : (
          <input
            placeholder="Your Answer"
            type="text"
            value={howLongAgoRes ? howLongAgoRes : answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        )}
      </div>

      {/* {flag ? <AiOutlineCalendar className="calIcon" /> : ""} */}
    </div>
  );
}

export default Textbox;
