import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
function EndSessionModal({ gotoParent }) {
  const navigate = useNavigate(null);

  const handleClick = () => {
    gotoParent();
  };
  const handleSession = () => {
    navigate("/Home");
  };

  return (
    <div className="overlay ">
      <div className="text-end">
        <span
          className="close"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.35718 3.46109L28.1795 27.2832M27.3027 3.46082L3.48028 27.2833"
              stroke="#636261"
              strokeWidth="6"
              strokeLinecap="round"
            />
          </svg>
        </span>
      </div>
      <div style={{ padding: "1rem 0" }}>
        <p
          style={{
            color: "#6B6B6B",
            textTransform: "capitalize",
            fontWeight: "500",
            fontSize: "1.1rem",
          }}
        >
          Are you sure you want to end the session ?
        </p>
        <div className="d-flex mx-auto gap-3 mt-4" style={{ width: "270px" }}>
          <button onClick={handleSession} className="startSession w-75">
            yes
          </button>
          <button onClick={handleClick} className="startSession w-75">
            no
          </button>
        </div>
      </div>
    </div>
  );
}

export default EndSessionModal;
