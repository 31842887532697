import React, { useState } from "react";
import SubmitFormModal from "../SubmitModal/SubmitFormModal";
import { useNavigate } from "react-router-dom";
function FormFooterbtns({ view, completed, SubmitForm, refetch, setRefetch }) {
  const [showOverlay, setShowOverlay] = useState(false);
  const navigate = useNavigate(null);
  const handleClick = () => {
    setShowOverlay(true);
  };
  const handleCompleteSession = () => {
    navigate("/Home");
  };

  const SubmitPatientForm = (text) => {
    // console.log(text);
    SubmitForm();
    setRefetch(!refetch);
    navigate("/Home");
    // if (text === "submit") {
    //   setShowOverlay(false);

    //   navigate("/completedpatientform");
    // } else if (text === "close") {
    //   setShowOverlay(false);
    // }
  };

  return (
    <div className="footerButtons d-flex justify-content-end gap-3 text-capitalize">
      {showOverlay && <div className="backgroundOverlay1"></div>}

      {showOverlay && <SubmitFormModal gotoParent={SubmitPatientForm} />}
      {view ? (
        <button
          onClick={() => navigate("/home")}
          className="rounded-4 text-capitalize"
          style={{
            width: "220px",
            padding: "0.9rem 1rem",
            background: "black",
            color: "white",
          }}
        >
          Close
        </button>
      ) : (
        <>
          <button
            className="rounded-4 text-capitalize"
            onClick={() => navigate("/home")}
            style={
              completed
                ? {
                    padding: "0.9rem 1rem",
                    width: "100px",
                    border: "1px solid #000000",
                  }
                : {
                    width: "130px",
                    padding: "0.9rem 1.4rem",
                    background: "white",
                  }
            }
          >
            Cancel
          </button>
          <button
            onClick={completed ? handleCompleteSession : handleClick}
            className="rounded-4 text-capitalize"
            style={
              completed
                ? {
                    width: "220px",
                    padding: "0.9rem 1rem",
                    background: "black",
                    color: "white",
                  }
                : {
                    width: "130px",
                    padding: "0.9rem 1.4rem",
                    background: "black",
                    color: "white ",
                  }
            }
          >
            {completed ? "complete session" : "submit"}
          </button>
        </>
      )}
    </div>
  );
}

export default FormFooterbtns;
