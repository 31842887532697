// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactUs{
    padding: 7rem 0;
     font-family: 'Poppins';
}
.contactUsWrapper{
    width: 80%;
    margin: 0 auto;
}

.contactUsWrapper h1{
    font-size: 1rem;
}
.contactUsWrapper h3{
   

font-weight: 600;
font-size: 1.2rem;
text-transform: uppercase;
/* identical to box height, or 142% */
text-align: center;

color: #ED1C24;
}

.contactUsWrapper h1{

font-weight: 500;
font-size: 2.8rem;
text-align: center;

margin-top: 2rem;

color: #000000;
}
.contactUsWrapper .row p{


font-weight: 400;
font-size: 1.3rem;

/* or 142% */


color: #0C0C0C;

}
.contactUsWrapper .d-flex p{


font-weight: 400;
font-size:1.1rem;

/* or 22px */


color: #000000;
}

.contactUsWrapper .contactusForm{
background: #FFFFFF;

box-shadow: 0px 3.91175px 19.5588px rgba(0, 0, 0, 0.28);
border-radius: 13.3714px;
padding: 1.5rem 3rem;

}

.contactusForm button{
    background-color: black;
    padding: 0.6rem 2.4rem;
    color: white;
    border-radius:12px ;
    -webkit-border-radius:12px ;
    -moz-border-radius:12px ;
    -ms-border-radius:12px ;
    -o-border-radius:12px ;
}`, "",{"version":3,"sources":["webpack://./src/pages/contact us/contactus.css"],"names":[],"mappings":"AAAA;IACI,eAAe;KACd,sBAAsB;AAC3B;AACA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;AACA;;;AAGA,gBAAgB;AAChB,iBAAiB;AACjB,yBAAyB;AACzB,qCAAqC;AACrC,kBAAkB;;AAElB,cAAc;AACd;;AAEA;;AAEA,gBAAgB;AAChB,iBAAiB;AACjB,kBAAkB;;AAElB,gBAAgB;;AAEhB,cAAc;AACd;AACA;;;AAGA,gBAAgB;AAChB,iBAAiB;;AAEjB,YAAY;;;AAGZ,cAAc;;AAEd;AACA;;;AAGA,gBAAgB;AAChB,gBAAgB;;AAEhB,YAAY;;;AAGZ,cAAc;AACd;;AAEA;AACA,mBAAmB;;AAEnB,uDAAuD;AACvD,wBAAwB;AACxB,oBAAoB;;AAEpB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;AAC1B","sourcesContent":[".ContactUs{\n    padding: 7rem 0;\n     font-family: 'Poppins';\n}\n.contactUsWrapper{\n    width: 80%;\n    margin: 0 auto;\n}\n\n.contactUsWrapper h1{\n    font-size: 1rem;\n}\n.contactUsWrapper h3{\n   \n\nfont-weight: 600;\nfont-size: 1.2rem;\ntext-transform: uppercase;\n/* identical to box height, or 142% */\ntext-align: center;\n\ncolor: #ED1C24;\n}\n\n.contactUsWrapper h1{\n\nfont-weight: 500;\nfont-size: 2.8rem;\ntext-align: center;\n\nmargin-top: 2rem;\n\ncolor: #000000;\n}\n.contactUsWrapper .row p{\n\n\nfont-weight: 400;\nfont-size: 1.3rem;\n\n/* or 142% */\n\n\ncolor: #0C0C0C;\n\n}\n.contactUsWrapper .d-flex p{\n\n\nfont-weight: 400;\nfont-size:1.1rem;\n\n/* or 22px */\n\n\ncolor: #000000;\n}\n\n.contactUsWrapper .contactusForm{\nbackground: #FFFFFF;\n\nbox-shadow: 0px 3.91175px 19.5588px rgba(0, 0, 0, 0.28);\nborder-radius: 13.3714px;\npadding: 1.5rem 3rem;\n\n}\n\n.contactusForm button{\n    background-color: black;\n    padding: 0.6rem 2.4rem;\n    color: white;\n    border-radius:12px ;\n    -webkit-border-radius:12px ;\n    -moz-border-radius:12px ;\n    -ms-border-radius:12px ;\n    -o-border-radius:12px ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
