// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questiontTextBox input {
  width: 218.75px;
  height: 40.1px;

  background: #fff2f3;
  border: 1px solid #8e8e8e;
  border-radius: 14.5833px;
  outline: none;
}
.questiontextboxWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}
.questiontextboxWrapper .form-check {
  text-align: center;
}
.questiontextboxWrapper p {
  font-family: "Poppins", sans-serif;

  font-size: 0.9rem;
}

.questiontTextBox .MuiOutlinedInput-root{
  height: 40.1px !important;
    background: #fff2f3;
    width: 218px;
  
     border-radius: 14.5833px;
   
}
.questiontTextBox .MuiOutlinedInput-input{
  border: none !important;
padding: 0 2rem;
top: -5px !important;
height: 40.1px !important;
 
}

.questiontTextBox .css-1ftyaf0{
float: left;
  border: 1px solid #8e8e8e;;
}
.questiontTextBox .MuiInputLabel-root {
  display: none;
}

.questiontTextBox .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
top: 0 !important;
 
  border: none
}

`, "",{"version":3,"sources":["webpack://./src/components/patientform/questionTextBox/questiontextbox.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;;EAEd,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;EACxB,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kCAAkC;;EAElC,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;IACvB,mBAAmB;IACnB,YAAY;;KAEX,wBAAwB;;AAE7B;AACA;EACE,uBAAuB;AACzB,eAAe;AACf,oBAAoB;AACpB,yBAAyB;;AAEzB;;AAEA;AACA,WAAW;EACT,yBAAyB;AAC3B;AACA;EACE,aAAa;AACf;;AAEA;AACA,iBAAiB;;EAEf;AACF","sourcesContent":[".questiontTextBox input {\n  width: 218.75px;\n  height: 40.1px;\n\n  background: #fff2f3;\n  border: 1px solid #8e8e8e;\n  border-radius: 14.5833px;\n  outline: none;\n}\n.questiontextboxWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  text-transform: capitalize;\n}\n.questiontextboxWrapper .form-check {\n  text-align: center;\n}\n.questiontextboxWrapper p {\n  font-family: \"Poppins\", sans-serif;\n\n  font-size: 0.9rem;\n}\n\n.questiontTextBox .MuiOutlinedInput-root{\n  height: 40.1px !important;\n    background: #fff2f3;\n    width: 218px;\n  \n     border-radius: 14.5833px;\n   \n}\n.questiontTextBox .MuiOutlinedInput-input{\n  border: none !important;\npadding: 0 2rem;\ntop: -5px !important;\nheight: 40.1px !important;\n \n}\n\n.questiontTextBox .css-1ftyaf0{\nfloat: left;\n  border: 1px solid #8e8e8e;;\n}\n.questiontTextBox .MuiInputLabel-root {\n  display: none;\n}\n\n.questiontTextBox .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{\ntop: 0 !important;\n \n  border: none\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
