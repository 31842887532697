import React from "react";
import "./about.css";
import about from "..//..//assets/aboutimg.png";
function About() {
  return (
    <div className="About">
      <div className="aboutWrapper">
        <div className="row">
          <div className="col-8 ">
            {" "}
            <h1>The Next Generation clinic & family care</h1>
            <p>
              Our Clinic lets you visit exceptional medical providers, get
              clinically-backed wellness services, and discover the right
              medicine, all in one place.
            </p>
          </div>
          <div className="col-4 text-end">
            <svg
              width="75"
              height="95"
              viewBox="0 0 75 95"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M60.9555 69.8157L41.3843 73.3214C41.7298 72.5259 41.9533 71.6646 41.9533 70.7352C41.9533 68.7381 41.1989 66.9729 39.7141 65.497C38.4288 64.2065 36.6606 63.5785 34.3061 63.5785H14.2508C13.9195 63.1928 13.447 62.9355 12.899 62.9355H3.04085C2.27523 62.9355 1.55746 63.2346 1.02057 63.7789C0.483739 64.321 0.192153 65.0402 0.200364 65.8051C0.245226 69.9412 0.370086 87.6578 0.264664 91.4074C0.242982 92.179 0.528593 92.9088 1.06841 93.4642C1.60075 94.0108 2.34321 94.3233 3.10515 94.3233H11.8604C13.4275 94.3233 14.7008 93.0485 14.7008 91.4829V87.9995L29.0189 90.9933C29.4293 91.0793 31.5123 90.9581 31.617 90.9073L63.639 84.8683C67.7894 84.1273 70.5633 80.1498 69.8244 75.9986C69.0962 71.9035 65.1731 69.0594 60.954 69.8153L60.9555 69.8157ZM11.0984 90.719H3.88701C3.96178 85.8598 3.8571 71.0675 3.81449 66.5407H11.0992L11.0984 90.719ZM62.992 81.3244L30.7193 87.4255C30.6109 87.4606 29.9993 87.5152 29.757 87.4651L14.7019 84.3174L14.7011 67.1829H34.3086C35.6732 67.1829 36.5785 67.4551 37.1671 68.0457C37.9746 68.8495 38.3507 69.7041 38.3507 70.7351C38.3507 72.1198 37.1125 73.5733 35.8812 74.309L25.0951 74.8429C24.1007 74.8676 23.3134 75.6952 23.3381 76.6896C23.3628 77.684 24.1867 78.4751 25.1848 78.4467C25.1848 78.4467 34.5667 78.2074 34.6555 78.1902L61.5919 73.3632C63.8162 72.9647 65.8926 74.4653 66.279 76.6312C66.6678 78.8257 65.2001 80.9289 62.9899 81.3245L62.992 81.3244Z"
                fill="#ED1C24"
              />
              <path
                d="M64.8449 9.17613H54.3767V4.86206C54.3775 2.1801 52.1972 0 49.516 0H35.7521C33.0724 0 30.8914 2.18029 30.8914 4.86206V9.17613H20.4225C15.2329 9.17613 11.0107 13.3983 11.0107 18.5879V44.6591C11.0107 49.8502 15.2329 54.0724 20.4225 54.0724H64.8456C70.0352 54.0724 74.2574 49.8502 74.2574 44.6591V18.5879C74.2574 13.3983 70.0352 9.17613 64.8441 9.17613H64.8449ZM34.4957 4.8613C34.4957 4.16745 35.0587 3.60371 35.7511 3.60371H49.5157C50.2081 3.60371 50.7711 4.16671 50.7711 4.8613V9.17537L34.4958 9.17612L34.4957 4.8613ZM70.6521 44.6601C70.6521 47.8638 68.0472 50.4688 64.8449 50.4688L20.4217 50.4681C17.2188 50.4681 14.6145 47.8633 14.6145 44.6593V18.5881C14.6145 15.3851 17.2193 12.7809 20.4217 12.7809H64.8449C68.0478 12.7809 70.6521 15.3857 70.6521 18.5881V44.6593V44.6601Z"
                fill="#ED1C24"
              />
              <path
                d="M50.1089 25.5907H48.6696V24.1515C48.6696 20.8243 45.9622 18.1191 42.636 18.1191C39.3103 18.1191 36.6023 20.8258 36.6023 24.1515V25.5907H35.1616C31.8344 25.5907 29.1279 28.2981 29.1279 31.6244C29.1279 34.9516 31.8354 37.658 35.1616 37.658H36.6009V39.0973C36.6009 42.4245 39.3083 45.1296 42.6345 45.1296C45.9602 45.1296 48.6682 42.4229 48.6682 39.0973V37.658H50.1074C53.4346 37.658 56.1397 34.9506 56.1397 31.6244C56.1412 28.2972 53.4361 25.5907 50.1089 25.5907H50.1089ZM50.1089 34.0529H45.0644V39.0974C45.0644 40.438 43.9766 41.5252 42.6353 41.5252C41.2947 41.5252 40.2062 40.438 40.2062 39.0974L40.207 34.0529H35.1617C33.8211 34.0529 32.7326 32.965 32.7326 31.6238C32.7326 30.2832 33.8205 29.1947 35.1617 29.1947H40.2062V24.1502C40.2062 22.8096 41.2941 21.7224 42.6353 21.7224C43.9759 21.7224 45.0644 22.8096 45.0644 24.1502V29.1947H50.1089C51.4495 29.1947 52.5367 30.2825 52.5367 31.6238C52.5367 32.9651 51.451 34.0529 50.1089 34.0529H50.1089Z"
                fill="#ED1C24"
              />
            </svg>
          </div>
        </div>

        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="col-md-6  text-start">
            <h3>our mission</h3>

            <p style={{ textAlign: "justify" }}>
              Medical emergencies can strike at any time, and do not
              discriminate who they effect. While these events can be stressful
              even in the best circumstances, when a language barrier is present
              it can impede or halt patient care, making a bad situation even
              worse. EMTran Pro can help with these issues by offering a variety
              of products and services that enable your staff to gather vital
              information at the most crucial of times, the initial patient
              contact.
            </p>
          </div>

          <div className="col-md-6 text-end">
            <img width="100%" src={about} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
