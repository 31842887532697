import { useState } from "react";

import CompletedSessions from "./CompletedSessions/CompletedSessions";
import SearchPatients from "./FilterPatients/SearchPatients";
function Home({ flag }) {
  const [searchText, setSearchText] = useState("");
  return (
    <div className="Home">
      <div
        className="appointmentWrapper w-75 mx-auto"
        style={{ padding: "2rem 0" }}
      >
        <SearchPatients searchText={searchText} setSearchText={setSearchText} />
        <CompletedSessions searchText={searchText} />
      </div>
    </div>
  );
}

export default Home;
