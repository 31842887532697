import React, { useState } from "react";
import { FormControlLabel, Radio } from "@mui/material";

const RadioBtn = ({ choice, optionsArray, index, quesId, gotoparent }) => {
  // function handleChange(e) {
  //   const response = optionsArray.indexOf(choice);
  //   gotoparent(response, quesId);
  // }

  return (
    <div
      className={
        optionsArray.length > 3
          ? "col-lg-2 col-sm-3 px-0 "
          : index === 0 || index === 1 || index === 17 || index === 22
          ? "col-2 px-0"
          : optionsArray.length < 4 && index < 0
          ? "col-4"
          : "col-6"
      }
      // style={index > 20 && index !== 22 ? { flex: 1 } : {}}
    >
      <FormControlLabel
        label={choice}
        value={choice}
        // onChange={handleChange}
        control={
          <Radio
            sx={{
              color: "gray",
              "&.Mui-checked": {
                color: "red",
                "& + .MuiFormControlLabel-label": {
                  color: "black",
                },
              },
            }}
          />
        }
      />
    </div>
  );
};

export default RadioBtn;
