import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { AuthContext } from "../../../context/AuthContext";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./login.css";

import axios from "axios";
function LoginForm() {
  const navigate = useNavigate(null);
  const [error, setError] = useState("");
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  const { LogIn } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submit = (data) => {
    console.log(data);
    // fetch("44.207.154.188/api/login", {

    //     // Adding method type
    //     method: "POST",

    //     // Adding body or contents to send
    //     body: JSON.stringify({
    //       "email": "user@gmail.com",
    //     "password": "12345"
    //     }),

    //     // Adding headers to the request
    //     headers: {
    //         "Content-type": "application/json; charset=UTF-8"
    //     }
    // })

    // // Converting to JSON
    // .then(response => response.json())

    // // Displaying results to console
    // .then(json => console.log(json));
    //   axios.post("http://44.207.154.188:3000/api/login", {
    //   email: "user@gmail.com",
    //   password: "12344"
    // })
    // .then((response) => {
    //   console.log(response);
    // });

    // var data1 = JSON.stringify({
    //   email: data.email,
    //   password: data.password,
    // });

    // // const headers = {

    // //   Accept:"application/json"

    // // }
    let data1 = JSON.stringify({
      email: data.email,
      password: data.password,
    });
    console.log(data1);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_HOST_URL}api/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data1,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.status) {
          setError("");
          LogIn(response);
          // navigate("/Home");
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    //  axios.post('44.207.154.188/api/login', data)
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    //   }

    // useEffect(()=>{

    // })
  };
  const handleReset = () => {
    navigate("/resetpassword");
  };

  return (
    <div>
      <form
        className="LogintextField"
        action="#"
        onSubmit={handleSubmit(submit)}
      >
        <div className="formFields">
          <div className="textField mb-4">
            <input
              style={errors.email ? { border: "1px solid #ed1c24" } : {}}
              {...register("email")}
              type="text"
              placeholder="abc@gmail.com"
            />
          </div>
          <div className="textField">
            <input
              {...register("password")}
              style={errors.password ? { border: "1px solid #ed1c24" } : {}}
              type="text"
              placeholder="******"
            />
          </div>
          <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.8rem" }}>
            {error}
          </p>
          {/* <div className="d-flex mt-2 justify-content-between">
            <p
              className="forgotPassword text-capitalize mb-0"
              onClick={handleReset}
            >
              forgot password ?
            </p>
          </div> */}
          <button className="submit">submit</button>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
