// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogintextField {
  margin-top: 1.5rem;
}

.formFields {
  width: 300px;
  margin: 0 auto;
}
.textField input {
  height: 50px;
  padding: 0 0.8rem;
  width: 100%;
  border: 1px solid #bebebf;
  /**/outline: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.checkbox p {
  font-size: 0.7rem;
  color: #7b7b7b;
}
.forgotPassword {
  color: #65a1fc;
  font-size: 0.9rem;
}
.forgotPassword:hover {
  cursor: pointer;
  text-decoration: underline;
} 
.LogintextField .submit {
  margin-top: 2em;
  width: 100%;
  padding: 1rem 0;
  border-radius: 12px;
  background-color: #000;
  color: white;
  text-transform: capitalize;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/LoginForm/login.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,yBAAyB;EACzB,GAAG,CAAC,aAAa;EACjB,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,eAAe;EACf,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,0BAA0B;EAC1B,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".LogintextField {\n  margin-top: 1.5rem;\n}\n\n.formFields {\n  width: 300px;\n  margin: 0 auto;\n}\n.textField input {\n  height: 50px;\n  padding: 0 0.8rem;\n  width: 100%;\n  border: 1px solid #bebebf;\n  /**/outline: none;\n  border-radius: 10px;\n  -webkit-border-radius: 10px;\n  -moz-border-radius: 10px;\n  -ms-border-radius: 10px;\n  -o-border-radius: 10px;\n}\n.checkbox p {\n  font-size: 0.7rem;\n  color: #7b7b7b;\n}\n.forgotPassword {\n  color: #65a1fc;\n  font-size: 0.9rem;\n}\n.forgotPassword:hover {\n  cursor: pointer;\n  text-decoration: underline;\n} \n.LogintextField .submit {\n  margin-top: 2em;\n  width: 100%;\n  padding: 1rem 0;\n  border-radius: 12px;\n  background-color: #000;\n  color: white;\n  text-transform: capitalize;\n  -webkit-border-radius: 12px;\n  -moz-border-radius: 12px;\n  -ms-border-radius: 12px;\n  -o-border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
