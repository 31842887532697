import React, { useState, useEffect } from "react";
import "./questiontextbox.css";
import Textbox from "./textBox/Textbox";
import DatePickerComp from "../../Datepicker/DatePickerComp";
import { createContext } from "react";
import moment from "moment";
export const Mycontext = createContext();
function QuestionTextBox({ quesId, gotoparent }) {
  const [name, setname] = useState("");
  const [value, setValue] = useState(moment().format("DD MMMM YYYY"));
  useEffect(() => {
    gotoparent(`${name},${value}`, quesId);
  }, [name, value]);

  console.log("QUESTION_ID", quesId);

  return (
    <div className="questiontTextBox">
      <div className="questiontextboxWrapper d-none d-md-flex mt-10">
        {/* <p className="mb-0 mr-10">what is your name?</p> */}
        {quesId === 3 && <Textbox placeholder="Josef Manchos" />}
        {/* <p className="mb-0 mr-10">what is your date of birth?</p> */}
        {quesId === 4 && <DatePickerComp />}
      </div>
      <div className="questiontextboxWrapperResponsive d-md-none d-block row">
        <div className="col-12 d-flex justify-content-between mb-4 align-items-center">
          {" "}
          {/* <p className="mb-0">what is your name?</p> */}
          {quesId === 3 && <Textbox placeholder="Josef Manchos" />}
        </div>
        <div className="col-12 d-flex justify-content-between align-items-center">
          {" "}
          {/* <p className="mb-0">what is your date of birth?</p> */}
          {quesId === 4 && <DatePickerComp />}
        </div>
      </div>
      <div className="marginBottom"></div>
    </div>
  );
}

export default QuestionTextBox;
