import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { UserResponseContext } from "../PatientForm/Questions";
import ColorPallete from "./colorpallets/ColorPallete";
const responses = [];
function DurationSlider({ options, quesId }) {
  // const [days, setDays] = useState("1");
  // const [week, setWeek] = useState("1");
  // const [months, setMonths] = useState("1");

  const {
    days,
    setDays,
    week,
    setWeek,
    months,
    setMonths,
    userresponse,
    setuserResponse,
  } = useContext(UserResponseContext);

  const setResponses = (response, duration) => {
    if (duration === "Months ") {
      setMonths(response);
    }
    if (duration === " Weeks ") {
      setWeek(response);
    }
    if (duration === " Days ") {
      setDays(response);
    }

    responses.push(response);
  };

  useEffect(() => {
    console.log(months, week, days);
    // if (responses.length > 2) {
    const userResponse = `${months}, ${week}, ${days}`;

    setuserResponse({ ...userresponse, [quesId]: userResponse });
    // }
  }, [months, week, days]);

  return (
    <div>
      <div className="sliderQues">
        <div className="colorPallets  text-capitalize d-xl-flex  justify-content-between  mt-4">
          <ColorPallete
            setResponses={setResponses}
            boxValue={[1, 2, 3, 4, 5, 6, 7, 8]}
            increment={1}
            duration={options[0]}
            gridSize={40}
            num={8}
            opacity={[0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1]}
            quesId={quesId}
            xPosition={months}
            type="Months"
            setMonths={setMonths}
          />
          <ColorPallete
            setResponses={setResponses}
            boxValue={[1, 2, 3, 4]}
            duration={options[2]}
            increment={0}
            gridSize={40}
            num={4}
            opacity={[0.85, 0.9, 0.95, 1]}
            quesId={quesId}
            xPosition={week}
            type="Weeks"
            setWeek={setWeek}
          />
          <ColorPallete
            setResponses={setResponses}
            increment={0}
            boxValue={[1, 2, 3, 4, 5, 6]}
            duration={options[4]}
            gridSize={40}
            num={6}
            opacity={[0.75, 0.8, 0.85, 0.9, 0.95, 1]}
            quesId={quesId}
            xPosition={days}
            type="Days"
            setDays={setDays}
          />
        </div>
      </div>
    </div>
  );
}

export default DurationSlider;
