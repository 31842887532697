import React, { useState } from "react";

import EndSessionModal from "./EndSessionModal";
import "..//Header.css";
function EndSessionBtn() {
  const [showOverlay, setShowOverlay] = useState(false);

  const hideOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div>
      {showOverlay && <div className="backgroundOverlay"></div>}
      {showOverlay && <EndSessionModal gotoParent={hideOverlay} />}
      <button onClick={() => setShowOverlay(true)} className="endSession">
        end session
      </button>
    </div>
  );
}

export default EndSessionBtn;
