import React, { useEffect, useRef } from "react";
import "./Header.css";
import { useState } from "react";
import StartSessionBtn from "./startSessionbtn/StartSessionbtn";
import { Link, useNavigate } from "react-router-dom";
import logo from "./..//..//assets/Logo.svg";
import { textTransform } from "@mui/system";
function Header({ childComp }) {
  const [activeIndex, setActiveIndex] = useState("0");
  // const [showOverlay, setShowOverlay] = useState(false);
  const [BtnText, setBtnText] = useState("Home");
  const [completeSession, setCompleteSesion] = useState(false);
  const menuText = useRef(null);
  const navigate = useNavigate(null);

  const handleMenu = (e) => {
    console.log(e.target.innerText);
    setBtnText(e.target.innerText);
    setActiveIndex(e.currentTarget.id);
  };
  useEffect(() => {
    navigate("/" + BtnText.replace(" ", "").toLowerCase());
  }, [BtnText]);
  return (
    <div className="topbar">
      <div className="topbarWrapper d-flex justify-content-between align-items-center mx-auto">
        <img
          className="emtranlogo"
          style={{ cursor: "pointer" }}
          src={logo}
          alt=""
          onClick={() => {
            navigate("/home", { replace: true });
          }}
        />
        <div className="flexMenu d-flex gap-4">
          <li
            ref={menuText}
            onClick={handleMenu}
            id="0"
            style={
              activeIndex === "0"
                ? {
                    borderBottom: "2px solid red",
                    color: "red",
                    // fontWeight: "bold",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }
                : { textTransform: "capitalize", cursor: "pointer" }
            }
          >
            Home
          </li>
          <li
            ref={menuText}
            onClick={handleMenu}
            id="1"
            style={
              activeIndex === "1"
                ? {
                    borderBottom: "2px solid red",
                    color: "red",
                    // fontWeight: "bold",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }
                : {
                    borderBottom: "2px solid transparent",
                    textTransform: "capitalize",
                    cursor: "pointer",
                  }
            }
          >
            about us
          </li>
          <li
            ref={menuText}
            onClick={handleMenu}
            id="2"
            style={
              activeIndex === "2"
                ? {
                    borderBottom: "2px solid red",
                    color: "red",
                    // fontWeight: "bold",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }
                : {
                    borderBottom: "2px solid transparent",
                    textTransform: "capitalize",
                    cursor: "pointer",
                  }
            }
          >
            contact us
          </li>
        </div>

        <div className="topbarButton">{childComp}</div>
      </div>
    </div>
  );
}

export default Header;
