import React from "react";
import LanguageConversion from "../../../languageCoverter/LanguageConversion";
import { useNavigate } from "react-router-dom";
import cross from ".//../..//..//..//assets//close.png";
import { Button } from "@mui/material";
function LanguageSelectionModal({ setShowOverlay, gotoParent }) {
  const navigate = useNavigate(null);
  const handleClick = () => {
    gotoParent();
  };
  return (
    <div className="overlay">
      <div className="text-end">
        <button style={{ border: "none", backgroundColor: "transparent" }}>
          <img src={cross} onClick={() => setShowOverlay(false)} />
        </button>
        {/* <span
              className="close"
              onClick={handleClick}
              style={{cursor:"pointer"}}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 32 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.35718 3.46109L28.1795 27.2832M27.3027 3.46082L3.48028 27.2833"
                  stroke="#636261"
                  stroke-width="6"
                  stroke-linecap="round"
                />
              </svg>
            </span> */}
      </div>
      <p
        style={{
          color: "#6B6B6B",
          textTransform: "capitalize",
          fontWeight: "500",
        }}
      >
        select your desired language
      </p>

      <LanguageConversion flag={true} />
      <div className="overlaybtn text-center mt-2">
        <button
          onClick={() => {
            navigate("/newpatientForm");
          }}
          className="startSession w-75"
        >
          submit
        </button>
      </div>
    </div>
  );
}
// onClick={handleClick}
export default LanguageSelectionModal;
