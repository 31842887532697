import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

import "./ColorPallets.css";
import marker from "./../../../../assets/marker.png";
import Draggable from "react-draggable";
let count = 0;
function ColorPallete({
  duration,
  setResponses,
  opacity,
  increment,
  boxValue,
  gridSize,
  num,
  ChangeSlider,
  xValue,
  setXAxisValue,
  xPosition,
  type,
  setMonths,
  setWeek,
  setDays,
}) {
  const myRef = useRef(null);

  const [SliderMovement, setSliderMovement] = useState(0);
  const [movement, setMovement] = useState(0);

  const [transformX, setTransformX] = useState(false);

  const [SliderValue, setSliderValue] = useState();
  const [size, setSize] = useState("");
  const [sliderWidth, setSlideerWidth] = useState("");

  // console.log(duration, JSON.parse(localStorage.getItem("response")));
  let currentPos;

  function handleDrag(e, elem) {
    console.log(e, elem);

    let selectedValue = null;

    switch (elem.x) {
      case 0:
        console.log("value is 1");
        selectedValue = "1";
        break;
      case 40:
        console.log("value is 2");
        selectedValue = "2";
        break;
      case 80:
        console.log("value is 3");
        selectedValue = "3";
        break;
      case 120:
        console.log("value is 4");
        selectedValue = "4";
        break;
      case 160:
        console.log("value is 5");
        selectedValue = "5";
        break;
      case 200:
        console.log("value is 6");
        selectedValue = "6";
        break;
      case 240:
        console.log("value is 7");
        selectedValue = "7";
        break;
      case 280:
        console.log("value is 8");
        selectedValue = "8";
        break;
      case 320:
        console.log("value is 9");
        selectedValue = "9";
        break;
    }

    console.log(selectedValue);

    if (type === "Months") {
      console.log("Getting Months Value");
      setMonths(selectedValue);
    } else if (type === "Weeks") {
      console.log("Getting Weeks Value");
      setWeek(selectedValue);
    } else if (type === "Days") {
      console.log("Getting Days Value");
      setDays(selectedValue);
    } else if (type === "previousDays") {
      setXAxisValue(elem.x);
    }

    // let lastPos = elem.lastX;
    // let currentPosition = elem.x;
    // let differnceInPosition = currentPosition - lastPos;
    // // console.log(elem);
    // if (differnceInPosition > 0) {
    //   count = count + 1;
    //   console.log("count is" + count);
    //   setSliderValue(SliderValue + increment);
    // } else if (differnceInPosition < count) {
    //   count = count - 1;
    //   setSliderValue(SliderValue - increment);
    // }
    // // let deltaX = elem.x;
    // console.log(elem);
    // let sliderPos = (count - 1) * size;
    // console.log("slider pos", sliderPos);
    // setSliderMovement(sliderPos);
    // setSliderValue(ans);
  }

  // function getPostion(e) {
  //   console.log("attributes", e.target.attributes);
  //   let clickedBlock = Number(e.target.innerText);

  //   let clickedNumber = Number(e.target.id);

  //   if (clickedNumber === boxValue.length) {
  //     ChangeSlider();
  //   }

  //   if (clickedBlock < SliderValue) {
  //     setTransformX(true);
  //     currentPos = (clickedNumber - 1) * 40 - SliderMovement;

  //     setMovement(currentPos);

  //     setSliderValue(clickedBlock);
  //     // (myarr) => [...myarr, { [duration]: clickedBlock }];
  //     // console.log(myarr);
  //     // setresponse(
  //     //   myarr.map((item) =>
  //     //     item.name === duration
  //     //       ? [...myarr, { [duration]: clickedBlock }]
  //     //       : item
  //     //   )
  //     // );
  //     setResponses(clickedBlock, duration);
  //   } else if (clickedBlock > SliderValue) {
  //     currentPos = (clickedNumber - 1) * 40 - SliderMovement;

  //     setTransformX(true);
  //     setMovement(currentPos);

  //     setSliderValue(clickedBlock);
  //     // console.log("response is", response);
  //     // setresponse([...response, { [duration]: clickedBlock }]);

  //     // myarr.map((item) =>
  //     //   Object.keys(item).join() == duration
  //     //     ? [...myarr, { [duration]: clickedBlock }]
  //     //     : item
  //     // )
  //     // setresponse((myarr) => [...myarr, { [duration]: clickedBlock }]);

  //     // gotoparent(response, quesId);
  //     setResponses(clickedBlock, duration);
  //   }
  // }

  useLayoutEffect(() => {
    function updateSize() {
      let sliderwidth = myRef.current.offsetWidth;
      // console.log("width" + sliderwidth);
      let width = myRef.current.offsetWidth / num;
      setSize(width);
      setSlideerWidth(sliderwidth);
    }
    updateSize();
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (transformX) {
      setResponses(SliderValue, duration);
    }
  }, [SliderValue]);

  // useEffect(() => {
  //   //   console.log(JSON.parse(localStorage.getItem("response")));
  //   // const arr = [];
  //   // localStorage.setItem("response", JSON.stringify(arr));
  //   // console.log("is null");
  //   // localStorage.setItem("response", JSON.stringify([]));
  //   // setresponse([]);
  // }, [SliderValue]);

  // useEffect(() => {
  //   // if (duration != "Months ") {
  //   //   console.log(duration, response);
  //   //   setresponse(JSON.parse(localStorage.getItem("response")));
  //   // }
  //   localStorage.
  // }, [SliderValue]);

  return (
    <div className="ColorPallete d-flex  align-items-center mb-5  ">
      <label className="duration">{duration}</label>
      <div
        style={{ width: `${40 * num}px` }}
        className="sliderParent"
        ref={myRef}
      >
        <div className="flexColors d-flex ">
          {boxValue.map((eachValue, index) => {
            return (
              <div
                // onClick={getPostion}
                data-slider-value={boxValue[index]}
                key={index}
                id={index + 1}
                value={index + 1}
                style={{ opacity: opacity[index], width: `${40 * num}px` }}
                className="item  d-flex align-items-center justify-content-center"
              >
                {boxValue[index]}
              </div>
            );
          })}
        </div>
        <div
          className="dragContainer position-relative"
          // style={{ width: 40 * num + "px" }}
        >
          <Draggable
            // onMouseDown={getdraggedElem}
            // onStop={handleStop}
            // draggable={false}
            grid={[gridSize]}
            bounds="parent"
            // defaultPosition={{ x: 0, y: 0 }}
            defaultPosition={{
              x: xPosition ? (xPosition - 1) * 40 : xValue ? xValue : 17,
              y: 0,
            }}
            // position={null}
            axis="x"
            onDrag={handleDrag}
            handle=".handle"
          >
            <img
              className="handle"
              draggable={false}
              style={{
                position: "absolute",

                left: transformX ? `${movement}px` : "",
                // right: sliderMoved ? `${(movement, 0)}px` : null,
              }}
              src={marker}
              alt=""
            />
          </Draggable>
        </div>
      </div>
    </div>
  );
}

export default ColorPallete;
