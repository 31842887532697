import React, { useEffect, useState, useRef } from "react";
import RadioBtn from "../radiobtn/RadioBtn";
import Textbox from "../questionTextBox/textBox/Textbox";
import DatePickerComp from "../../Datepicker/DatePickerComp";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useContext } from "react";
import { UserResponseContext } from "../PatientForm/Questions";
import QuestionSelectDays from "../questionSelectDays/QuestionSelectDays";
import PainScale from "../../Painscale/PainScale";
import TableQuestion from "../questionTable/TableQuestion";
import DurationSlider from "../WomenPatient/DurationSlider";
import { BodyComponent } from "reactjs-human-body";
import "./Question.css";
import { object } from "yup";

const Question = ({
  view,
  ques,
  options,
  index,
  quesId,
  section,
  xValue,
  radioBtnOption,
  bodyPainData,
  painOptions,
  tableDataObj,
  tableOptions,
  viewPatienFormDetails,
}) => {
  const [selectedoption, setSelectedOption] = useState(radioBtnOption);
  const [params, setParams] = useState({ ...bodyPainData });
  // const [params, setParams] = useState({
  //   left_arm: { selected: true },
  // });
  const [paramsSelected, setParamsSelected] = useState({});
  const [painType, setPainType] = useState(["Bleed", "Hurt", "Pain"]);
  const [selectedBody, setSelectedBody] = useState();
  const [bodyPainSelection, setBodyPainSelection] = useState({});
  const [paramsList, setParamsList] = useState([]);
  const { userresponse, setuserResponse } = useContext(UserResponseContext);
  const radioBtnsArray = [
    1, 2, 5, 6, 7, 9, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26,
    27, 28, 31,
  ];

  // console.log(bodyPainData);

  useEffect(() => {
    setSelectedOption(radioBtnOption);
  }, []);

  const womenQues = [19, 20, 21, 22];
  const carQues = [24, 25, 26, 27];

  function handleBody(paramsClicked) {
    console.log("handleBody ", paramsClicked);

    let obj = paramsList;

    obj.push(paramsClicked);
    console.log(obj);
    setParamsList(obj);
  }

  function handleBodyChange(params) {
    console.log("handleBody change", params);
    setParams(params);
    setParamsSelected(params);
    console.log(params);
    setuserResponse({
      ...userresponse,
      [quesId]: { params: JSON.stringify(params) },
    });
  }

  function handleChange(e) {
    const feedback = e.target.value;
    const response = options.indexOf(feedback);
    // console.log("response",response)
    // gotoparent(response, quesId);
    setuserResponse({ ...userresponse, [quesId]: response });

    setSelectedOption(e.target.value);
  }

  function handleChangeBodyPain(event, part) {
    console.log("body pain change", event, part);
    let obj = bodyPainSelection;
    if (obj[part] == undefined) {
      obj[part] = "";
    }
    const painObj = event;
    // painObj[event] = true;
    obj[part] = painObj;
    console.log(obj);
    setBodyPainSelection(obj);
    setuserResponse({
      ...userresponse,
      [quesId]: {
        ...userresponse[quesId],
        optionSelected: JSON.stringify(obj),
      },
    });
  }

  // console.log(painType, params, bodyPainSelection);
  // console.log(Object.keys(bodyPainSelection));
  console.log(userresponse[4]);

  return (
    <div className="question">
      <div className="questionWrapper5  align-items-center">
        {index === 19 && <h5 style={{ fontWeight: "bolder" }}>WOMEN ONLY</h5>}
        {index === 24 && (
          <h5 style={{ fontWeight: "bolder" }}>CAR WRECKS ONLY</h5>
        )}
        <div
          className={
            "mb-sm-4 mb-lg-0 align-items-center row col-12 justify-content-between"
          }
        >
          <p className={`questionLabel mb-sm-0 col-4`}>{ques}</p>
          {index === 8 && (
            <>
              <PainScale
                view={view}
                quesId={quesId}
                painScaleRes={viewPatienFormDetails?.painScaleRes}
              />
            </>
          )}
          {quesId === 3 && (
            <div className="float-left col-4">
              <Textbox
                quesId={quesId}
                name={true}
                nameRes={viewPatienFormDetails?.nameRes}
              />
            </div>
          )}
          {quesId === 4 && (
            <div className="col-4">
              <DatePickerComp
                quesId={quesId}
                className={"col-4"}
                dateRes={viewPatienFormDetails?.dateRes}
              />
            </div>
          )}
          {index === 10 && (
            <div className="row">
              <div className="col" style={{ marginTop: "20px" }}>
                {Object.keys(params)?.map(
                  (part) =>
                    params[part]["selected"] && (
                      <div className="row ">
                        <label className={`mb-sm-0 col-2`}>
                          <b>{part}</b>
                        </label>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={painOptions ? painOptions[part] : ""}
                          name="radio-buttons-group"
                          onChange={(event) =>
                            handleChangeBodyPain(event.target.value, part)
                          }
                        >
                          {painType?.map((painChoice) => {
                            console.log("painchoice", painChoice);
                            return (
                              <FormControlLabel
                                label={painChoice}
                                value={painChoice}
                                control={
                                  <Radio
                                    sx={{
                                      color: "gray",
                                      "&.Mui-checked": {
                                        color: "red",
                                        "& + .MuiFormControlLabel-label": {
                                          color: "red",
                                        },
                                      },
                                    }}
                                  />
                                }
                              />
                            );
                          })}
                        </RadioGroup>
                      </div>
                    )
                )}
              </div>
              <div className="col" style={{ marginTop: "-80px" }}>
                <BodyComponent
                  partsInput={params}
                  onClick={handleBody}
                  onChange={handleBodyChange}
                />
              </div>
              {/* <div className="col"></div> */}
            </div>
          )}
          {index === 14 && (
            <TableQuestion
              quesId={quesId}
              tableDataObj={tableDataObj}
              tableOptions={tableOptions}
              userresponse={userresponse}
              setuserResponse={setuserResponse}
            />
          )}
          {index === 23 && <DurationSlider quesId={quesId} options={options} />}
          {quesId === 30 && (
            <div
              style={{ marginTop: "20px", marginLeft: "-20px" }}
              className="float-left col-12"
              // style={{ border: "2px solid black" }}
            >
              {/* <Textbox
                quesId={quesId}
                name={false}
                howLongAgoRes={viewPatienFormDetails?.howLongAgoRes}
              /> */}
              <QuestionSelectDays
                quesId={quesId}
                section={section}
                xValue={xValue}
              />
            </div>
          )}
          {/* {
            textBoxIndex===2 && <QuestionTextBox/>
          } */}
          {radioBtnsArray.includes(index) && (
            <RadioGroup
              onChange={handleChange}
              value={selectedoption}
              className={
                options.length > 2 && index < 16
                  ? "mt-3"
                  : index >= 0
                  ? "col-8"
                  : index === 23
                  ? "col-6 !important"
                  : "col-6 !important"
              }
              style={{
                paddingLeft:
                  index === 5 || index === 11 || index === 12 || index === 13
                    ? "2rem"
                    : "",
              }}
            >
              <div
                className={
                  options.length === 5 || options.length === 6
                    ? "form-check row justify-content-between"
                    : "form-check row"
                }
                style={{ width: index === 25 ? "133%" : "107%" }}
              >
                {options.map((option) => (
                  <RadioBtn
                    // gotoparent={gotoparent}
                    radioBtnOption={radioBtnOption}
                    optionsArray={options}
                    index={index}
                    choice={option}
                    quesId={quesId}
                  />
                ))}
              </div>
            </RadioGroup>
          )}
        </div>
        {womenQues.includes(index) ? (
          <div></div>
        ) : carQues.includes(index) ? (
          <div></div>
        ) : (
          <div className="marginBottom"></div>
        )}
      </div>
    </div>
  );
};

export default Question;
