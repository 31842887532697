import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ component }) => {
  const { isLoggedIn } = useContext(AuthContext);

  const login = isLoggedIn();

  return login ? component : <Navigate to="/" />;
};

export default ProtectedRoutes;
