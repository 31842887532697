import React, { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UserResponseContext } from "../patientform/PatientForm/Questions";
import Stack from "@mui/material/Stack";

import moment from "moment";
function DatePickerComp({ quesId, dateRes }) {
  const { date, setDate, userresponse, setuserResponse } =
    useContext(UserResponseContext);
  // console.log(moment(date).year(), moment().year());

  useEffect(() => {
    setuserResponse({ ...userresponse, [quesId]: date });
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DatePicker
          inputFormat="dd MMMM yyyy"
          // disableFuture
          // label={false}
          value={dateRes ? dateRes : date}
          onChange={(newValue) => {
            setDate(moment(newValue).format("DD MMMM YYYY"));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              // variant="standard"
              sx={{
                width: "60%",
                backgroundColor: "#fff2f3;",
                borderColor: "transparent",
                // borderRadius: "50px",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default DatePickerComp;
