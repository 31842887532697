// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTableCell-root {

  text-transform: capitalize;
 font-family: 'Poppins', sans-serif !important;
}
.MuiTableCell-body {
  color: #575656 !important;
  font-size: 1rem !important;
}

.Home .MuiTableRow-head   {

  background-color: #000 !important;
  font-family: "Poppins";
}

.MuiTableHead-root tr th {
  color: white;
  font-size: 1.2rem;
  text-transform: capitalize;
}
.buttonDelete:hover{
  background: linear-gradient(90deg,#ff5157,#940308 );
}

.buttonDelete{
  background-color:#d61f26
}`, "",{"version":3,"sources":["webpack://./src/components/Home/CompletedSessions/CompletedSessions.css"],"names":[],"mappings":"AAAA;;EAEE,0BAA0B;CAC3B,6CAA6C;AAC9C;AACA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;;EAEE,iCAAiC;EACjC,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;AAC5B;AACA;EACE,mDAAmD;AACrD;;AAEA;EACE;AACF","sourcesContent":[".MuiTableCell-root {\n\n  text-transform: capitalize;\n font-family: 'Poppins', sans-serif !important;\n}\n.MuiTableCell-body {\n  color: #575656 !important;\n  font-size: 1rem !important;\n}\n\n.Home .MuiTableRow-head   {\n\n  background-color: #000 !important;\n  font-family: \"Poppins\";\n}\n\n.MuiTableHead-root tr th {\n  color: white;\n  font-size: 1.2rem;\n  text-transform: capitalize;\n}\n.buttonDelete:hover{\n  background: linear-gradient(90deg,#ff5157,#940308 );\n}\n\n.buttonDelete{\n  background-color:#d61f26\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
