// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionWrapper {
  width: 80%;
}

.questionWrapper .para {
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  width: 340px;
  margin-bottom: 0;
  font-size: 0.9rem;

  font-family: "Poppins", sans-serif;
}

.form-check {
  display: flex;
  padding-left: 0;
}

.alignRadioBtn-1st {
  flex: 0 0 auto ! important;
  width: 16.7%;
  padding-left: 0;
  padding-right: 0;
}

.alignRadioBtn-2nd {
  flex: 0 0 auto ! important;
  width: 18%;
  padding-left: 0;
  padding-right: 0;
}

/* question5  */

.questionWrapper5 p {
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;

  /* max-width: 340px; */
  font-size: 0.9rem;
}

.question4Para {
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;

  font-size: 0.9rem;
}
.bleed path {
  fill:#9D0208 !important
}
.pain path {
  fill:#E85D04 !important
}

.hurt path {
  fill:#FFBA08 !important
} `, "",{"version":3,"sources":["webpack://./src/components/patientform/Question/Question.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,0BAA0B;EAC1B,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;;EAEjB,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;;AAEA,eAAe;;AAEf;EACE,WAAW;EACX,gBAAgB;EAChB,0BAA0B;EAC1B,kCAAkC;;EAElC,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,0BAA0B;EAC1B,kCAAkC;EAClC,gBAAgB;;EAEhB,iBAAiB;AACnB;AACA;EACE;AACF;AACA;EACE;AACF;;AAEA;EACE;AACF","sourcesContent":[".questionWrapper {\n  width: 80%;\n}\n\n.questionWrapper .para {\n  color: #000;\n  font-weight: 400;\n  text-transform: capitalize;\n  width: 340px;\n  margin-bottom: 0;\n  font-size: 0.9rem;\n\n  font-family: \"Poppins\", sans-serif;\n}\n\n.form-check {\n  display: flex;\n  padding-left: 0;\n}\n\n.alignRadioBtn-1st {\n  flex: 0 0 auto ! important;\n  width: 16.7%;\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.alignRadioBtn-2nd {\n  flex: 0 0 auto ! important;\n  width: 18%;\n  padding-left: 0;\n  padding-right: 0;\n}\n\n/* question5  */\n\n.questionWrapper5 p {\n  color: #000;\n  font-weight: 400;\n  text-transform: capitalize;\n  font-family: \"Poppins\", sans-serif;\n\n  /* max-width: 340px; */\n  font-size: 0.9rem;\n}\n\n.question4Para {\n  color: #000;\n  font-weight: 400;\n  text-transform: capitalize;\n  font-family: \"Poppins\", sans-serif;\n  margin-bottom: 0;\n\n  font-size: 0.9rem;\n}\n.bleed path {\n  fill:#9D0208 !important\n}\n.pain path {\n  fill:#E85D04 !important\n}\n\n.hurt path {\n  fill:#FFBA08 !important\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
