import React from "react";
import "./Form.css";
import loginLogo from "..//..//assets//loginLogo.png";
import loginTextLogo from "..//..//assets//LoginText.png";
import { Link, NavLink } from "react-router-dom";
function Form(props) {
  const {flag,buttontext, text, childComp } = props;
const backBtn={

width:"max-content",
  padding: "0.6rem 2rem",
  borderRadius: "12px",
  backgroundColor:"#000",
  color:"white",
  textTransform: "capitalize",

}
  return (
    <div  className="form">
      <div className="formWrapper">
       {flag && <Link to="/"><button style={backBtn} >{buttontext}</button></Link> } 
        <div className="logo text-center mb-4">
          <img src={loginLogo} alt="" />
        </div>
        <div className="logintextlogo text-center mb-4">
          <img src={loginTextLogo} alt="" />
        </div>
      
        <h3>{text}</h3>
          {text==="Reset password"?<p style={{color:"#7B7B7B",fontSize:"0.6rem",textAlign:"center"}}>Please choose your new password</p>:""}
        <div>{childComp}</div>
      </div>
    </div>
  );
}

export default Form;
