import React, { useEffect, useContext } from "react";
import { useState } from "react";
import RadioBtn from "../radiobtn/RadioBtn";
import "./questionSelectDays.css";
import { RadioGroup } from "@mui/material";
import { UserResponseContext } from "../PatientForm/Questions";
import ColorPallete from "../WomenPatient/colorpallets/ColorPallete";
const array = ["Minutes", "Hours", "Days", "Weeks", "Months"];
const opacities = {
  opacity1: [0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1],
  opacity2: [0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1],
  opacity3: [0.85, 0.9, 0.95, 1],
};
const minutes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, "1 hour"];
const hours = [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, "1 day"];
const days = [1, 2, 3, 4, 5, 6, "1 week"];
const weeks = [1, 2, 3, "1 month"];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, "1 year"];
function QuestionSelectDays({ value, ques, quesId, section, xValue }) {
  const { userresponse, setuserResponse } = useContext(UserResponseContext);
  const [activetab, setActivetab] = useState(0);
  const [xAxisValue, setXAxisValue] = useState();
  // function handleClick(e, index) {
  //   setActivetab();
  // }
  console.log("section", section, xValue);
  useEffect(() => {
    if (section) {
      setActivetab(section);
    }
  }, [section]);

  useEffect(() => {
    if (activetab || xAxisValue) {
      console.log("SLIDERANSWER", { section: activetab, xValue: xAxisValue });
      setuserResponse({
        ...userresponse,
        [quesId]: JSON.stringify({ section: activetab, xValue: xAxisValue }),
      });
    }
  }, [activetab, xAxisValue]);

  return (
    <div class="QuestionSelectDays">
      <div className="QuestionRadioButtonsWrapper">
        {quesId === 30 ? (
          <div>
            <ul className="px-0 d-flex ">
              {array.map((duration, index) => {
                return (
                  <li
                    id={index}
                    key={index}
                    onClick={() => {
                      setActivetab(index);
                    }}
                    className={`list-unstyled  mx-3 ${
                      index === activetab ? "active" : ""
                    }`}
                    style={{ color: `${index === activetab ? "black" : ""}` }}
                  >
                    {duration}
                  </li>
                );
              })}
            </ul>
            {activetab === 0 && (
              <ColorPallete
                boxWidth={100}
                opacity={opacities.opacity1}
                boxValue={minutes}
                // handlePosition={30}
                num={20}
                gridSize={66}
                quesId={quesId}
                xValue={xValue}
                setXAxisValue={setXAxisValue}
                type="previousDays"
                sliderWidth={94}
                increment={5}
                ChangeSlider={() => setActivetab(activetab + 1)}
              />
            )}
            {activetab === 1 && (
              <ColorPallete
                opacity={opacities.opacity1}
                boxValue={hours}
                // handlePosition={30}
                num={21.5}
                quesId={quesId}
                xValue={xValue}
                setXAxisValue={setXAxisValue}
                type="previousDays"
                gridSize={66}
                increment={2}
                ChangeSlider={() => setActivetab(activetab + 1)}
                sliderWidth={94}
              />
            )}
            {activetab === 2 && (
              <ColorPallete
                ChangeSlider={() => setActivetab(activetab + 1)}
                opacity={opacities.opacity2}
                boxValue={days}
                // handlePosition={30}
                num={12}
                quesId={quesId}
                xValue={xValue}
                setXAxisValue={setXAxisValue}
                type="previousDays"
                gridSize={68}
                increment={1}
              />
            )}
            {activetab === 3 && (
              <ColorPallete
                ChangeSlider={() => setActivetab(activetab + 1)}
                opacity={opacities.opacity3}
                boxValue={weeks}
                // handlePosition={30}
                num={7}
                quesId={quesId}
                xValue={xValue}
                setXAxisValue={setXAxisValue}
                type="previousDays"
                gridSize={68}
                // sliderWidth={78}
                increment={1}
              />
            )}
            {activetab === 4 && (
              <ColorPallete
                ChangeSlider={() => setActivetab(activetab)}
                opacity={opacities.opacity1}
                boxValue={months}
                // handlePosition={30}
                num={20}
                quesId={quesId}
                xValue={xValue}
                setXAxisValue={setXAxisValue}
                type="previousDays"
                gridSize={66}
                increment={1}
              />
            )}
          </div>
        ) : (
          ""
        )}
        {/* <p>{ques}</p> */}
        <div className="gridRadioButtons">
          <div className="row">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {/* {value.map((label) => {
                return (
                  <div
                    className={` col-lg-2 ${
                      quesId === "7" || quesId === "8" ? "col-sm-4" : "col-sm-3"
                    }`}
                  >
                    <RadioBtn choice={label} />
                  </div>
                );
              })} */}
            </RadioGroup>
          </div>
        </div>
      </div>
      {/* <div className="marginBottom"></div> */}
    </div>
  );
}
export default QuestionSelectDays;
