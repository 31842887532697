// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversionComponent {
  height: 3rem;
  border: 1px solid #d3d3d3;
  box-shadow: inset 0px 0px 20px 2px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif !important;
}
/* .languageconversionWrapper {
  width: 50%;
  margin: 0 auto;
} */

.languageconversionWrapper .language {
  color: #6b6b6b;
  font-weight: 500;
}
.languageconversionWrapper .MuiFormControl-root {
  width: 7rem !important;
}

.languageconversionWrapper .MuiOutlinedInput-root {
  height: 2rem;
}
.languageconversionWrapper .css-hdw1oc {
  display: none !important ;
}
.languageconversionWrapper .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-yf8vq0-MuiSelect-nativeInput{
  bottom: 0.3rem !important;
  opacity: 1 !important;
  border: none !important;
}
.iconsConversion .arrow{
  width: 25px;
  height: 25px;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paperr{
  height: 200px !important;
   position: relative !important;
margin-top: 100px !important;
width: 200px !important;
} */

/* .css-6hp17o-MuiList-root-MuiMenu-list{
  height: 200px !important;
} */`, "",{"version":3,"sources":["webpack://./src/components/languageCoverter/languageconversion.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,sDAAsD;EACtD,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,6CAA6C;AAC/C;AACA;;;GAGG;;AAEH;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;;;;GAKG;;AAEH;;GAEG","sourcesContent":[".conversionComponent {\n  height: 3rem;\n  border: 1px solid #d3d3d3;\n  box-shadow: inset 0px 0px 20px 2px rgba(0, 0, 0, 0.01);\n  border-radius: 10px;\n  display: flex;\n  justify-content: space-between;\n  font-family: \"Poppins\", sans-serif !important;\n}\n/* .languageconversionWrapper {\n  width: 50%;\n  margin: 0 auto;\n} */\n\n.languageconversionWrapper .language {\n  color: #6b6b6b;\n  font-weight: 500;\n}\n.languageconversionWrapper .MuiFormControl-root {\n  width: 7rem !important;\n}\n\n.languageconversionWrapper .MuiOutlinedInput-root {\n  height: 2rem;\n}\n.languageconversionWrapper .css-hdw1oc {\n  display: none !important ;\n}\n.languageconversionWrapper .MuiOutlinedInput-notchedOutline {\n  border: none !important;\n}\n\n.css-yf8vq0-MuiSelect-nativeInput{\n  bottom: 0.3rem !important;\n  opacity: 1 !important;\n  border: none !important;\n}\n.iconsConversion .arrow{\n  width: 25px;\n  height: 25px;\n}\n\n/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paperr{\n  height: 200px !important;\n   position: relative !important;\nmargin-top: 100px !important;\nwidth: 200px !important;\n} */\n\n/* .css-6hp17o-MuiList-root-MuiMenu-list{\n  height: 200px !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
